<template>    
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">              
          <div class="d-flex justify-content-end">           
            <div>
              <router-link v-if="isUserLogin && !isMyAccountPage" class="nav-link" to="/adminMainPage">Admin Settings&nbsp;&nbsp;</router-link>                         
            </div>            
            <div>            
              <router-link v-if="isUserLogin" class="nav-link" to="/admin" @click.prevent="handleSignOut">Sign out</router-link>            
              <router-link class="nav-link" to="/admin" v-else>Home</router-link>              
            </div>
          </div>                                                                 
        </nav>        
      </div>                

    <main class="container-fluid mt-1">
      <router-view />
    </main>

    <footer>
      <p>&copy; 2024 Chen Home Tech Inc. All rights reserved.</p>
    </footer>

</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { useRoute } from 'vue-router';

const store = useStore();    
const toast = useToast();
const route = useRoute();

// const isLoginPage = computed(() => route.path === '/admin');
const isUserLogin = computed(() => store.getters.isAdminLogin);
const isMyAccountPage = computed(() => route.path === '/adminMainPage');

function handleSignOut() {
  store.dispatch('logoutAdmin');

  toast.info('Logout admin successful, redirecting to the home page... ');
}


</script>

<style scoped>
  
body, html {
  margin: 0;
  padding: 0;
  width: 100%;    
}

</style>