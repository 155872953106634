<template>    
    <div class="row">
        <div class="container">
            <div class="row">
                <div class="d-flex justify-content-between">
                    <div>
                        <h3>Hi, {{ curAdmin?.userName }}.</h3> 
                    </div>                    
                </div>

                <hr class="divider-line0" />
    
                <h2>Staff Group Settings</h2>
            </div>

            <div class="row" style="padding-bottom: 30px; padding-left: 20px;">
                <div :class="getNoDataClass(groupData)">
                    <div style="width:100%;">
                        <div v-if="groupData && groupData.length<=0" style="text-align: center;">No group data</div>
                        <div v-for="group in groupData" :key="group?.id" class="summary">
                            <div class="d-flex justify-content-start" style="background-color: #ccc; align-items: center; padding: 10px; padding-left: 16px; font-size: 14px; color:#333;">                                
                                <div style="font-weight: 600;"><el-tooltip :content="'#'+group?.dbGroup?.id">No# {{ group?.dbGroup?.groupNo }}</el-tooltip></div>
                            </div>
                            <div><hr class="divider-line1" /></div>
                            <div class="d-flex justify-content-between" style="padding-top: 10px; padding-left: 16px; font-size: 14px; color:#333;">
                                <div>
                                    <h4>Staffs: {{ group?.staffs.length }}</h4>
                                </div>                                
                            </div>
                            <div :class="getNoDataClass(group.staffs)" style="padding-top: 0px; padding-bottom: 10px; padding-left: 16px; font-size: 14px; color:#333;">
                                <div style="width: 100%;">
                                <div v-if="group.staffs && group.staffs.length<=0" style="text-align: center;">No staff data</div>                                
                                <div v-for="staff in group.staffs" :key="staff?.staffId" class="summary"> 
                                    <div class="d-flex justify-content-start" style="background-color: #ccc; align-items: center; padding: 10px; padding-left: 20px; font-size: 14px; color:#333;">
                                        <div>
                                            <el-tooltip :content="'#'+ staff?.staffId">
                                                <el-avatar :src="'/staffs/'+ staff?.staffNo + '.jpg'" fit="contain" class="avatar" :class="setDisabledImgClass(staff?.disabled)"/>                                
                                            </el-tooltip>
                                        </div>
                                        <div style="color: white; font-weight: 600; padding-left: 10px;">
                                            No# {{ staff?.staffNo }} 
                                            <br>
                                            {{ staff?.firstName }} {{ staff?.lastName }}                                            
                                        </div>        
                                        <div style="padding-left: 80px;">
                                            <el-rate
                                                v-model="staff.rate"
                                                disabled                                                                           
                                            />
                                        </div>             
                                        <div style="color: #ff9900; margin-top: 4px;">
                                            {{ staff?.rate }}
                                        </div>                   
                                    </div>
                                    <div class="d-flex justify-content-end" style="align-items: center; padding: 10px; padding-right: 16px; padding-left: 16px; font-size: 14px;">
                                        <div class="links">
                                            <a href="#" @click.prevent="handleRemoveStaffFromGroup(staff?.staffId, group?.dbGroup?.id)">Remove from group</a>
                                        </div>
                                    </div>
                                </div>     
                                </div>                           
                            </div>

                            <div><hr class="divider-line1" /></div>

                            <div class="d-flex justify-content-end" style="align-items: center; padding: 10px; padding-right: 16px; padding-left: 16px; font-size: 14px;">
                                <div class="links" style="padding-right: 40px;">
                                    <a href="#" @click.prevent="handleAddStaffToGroup(group?.dbGroup?.id)">Add staff to group</a>
                                </div>
                                <div class="links">
                                    <a href="#" @click.prevent="handleEditGroup(group?.dbGroup?.id)">Edit</a> <span style="color:darkgray" v-if="group?.staffs.length<=0">|</span> <a href="#" v-if="group?.staffs.length<=0" @click.prevent="handleRemoveGroup(group?.dbGroup?.id)">Remove</a>
                                </div>
                            </div>
                        </div>
                        <div  class="d-flex justify-content-end" style="padding-bottom: 20px; padding-top: 0px; padding-right: 20px; width: 98%; font-size: 14px;">
                            <div class="links">
                                <a href="#" @click.prevent="handleAddGroup">Add new group</a>
                            </div>                    
                        </div>
                    </div>

                    <el-dialog v-model="groupEditElem.showDlg" width="600" destroy-on-close>
                        <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                            <span style="color: #333; font-size: 16px;; font-weight: 600;">{{ getDlgGroupMainTitle() }}</span>
                        </div>
                        <hr class="divider-line1"/>
                        <div class="d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 10px;">
                            <div>
                                <el-input
                                v-model="groupEditElem.groupNo"
                                style="width: 420px"
                                placeholder="Group No"                            
                                />
                            </div>
                        </div>
                        <form @submit.prevent="handleSubmitGroup">
                            <div class="d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 30px;">
                                <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">{{ getDlgGroupBtnText() }}</span>
                                <span v-else class="spinner"></span>
                                </button>
                            </div>
                        </form>
                    </el-dialog>

                    <el-dialog v-model="groupStaffElem.showDlg" width="560" destroy-on-close>
                        <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                            <span style="color: #333; font-size: 16px;; font-weight: 600;">Available Staff List</span>
                        </div>
                        <hr class="divider-line1"/>
                        <div :class="getNoDataClass(groupStaffElem.availableStaffs)" style="padding-bottom: 10px; padding-top: 20px;">                            
                            <div v-if="groupStaffElem.availableStaffs && groupStaffElem.availableStaffs.length<=0">No available staff</div>
                            <div class="row" style="padding-left: 16px; padding-top: 10px;">
                                <div v-for="addingStaff in groupStaffElem.availableStaffs" :key="addingStaff?.id">
                                    <div class="d-flex justify-content-start" style="padding-bottom: 26px;">                                       
                                        <div>
                                            <el-checkbox-group v-model="groupStaffElem.selectedStaffs">
                                                <el-checkbox :value="addingStaff.id" label="" border > 
                                                    <div class="d-flex justify-content-start" style="align-items: center;">
                                                        <div style="padding-left: 10px;">
                                                            <el-tooltip :content="'#'+ addingStaff?.id"><el-avatar :src="'/staffs/'+ addingStaff?.staffNo + '.jpg'" fit="contain" class="avatar" :class="setDisabledImgClass(addingStaff?.disabled)"/></el-tooltip>
                                                        </div>
                                                        <div style="padding-left: 10px;">
                                                            {{ addingStaff?.firstName }} {{ addingStaff?.lastName }}
                                                        </div>
                                                        <div style="padding-left: 20px;">
                                                            No# {{ addingStaff?.staffNo }}
                                                        </div>
                                                        <div style="padding-left: 20px;">
                                                            <el-rate
                                                                v-model="addingStaff.rate"
                                                                disabled                                                                                        
                                                            />
                                                        </div>
                                                        <div style="color: #ff9900; margin-top: 4px;">
                                                            {{ addingStaff?.rate }}
                                                        </div>
                                                    </div>                                                
                                                </el-checkbox>
                                            </el-checkbox-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form @submit.prevent="handleSubmitAddStaffToGroup">
                            <div class="d-flex justify-content-center" style="padding-top: 0px; padding-bottom: 30px;">
                                <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">Add selected staffs</span>
                                <span v-else class="spinner"></span>
                                </button>
                            </div>
                        </form>
                    </el-dialog>
                </div>
            </div>
        </div>
    </div>  
</template>


<script setup>
import { useRouter } from 'vue-router'; 
import { useStore } from 'vuex';
import { getCurrentInstance,ref,onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import apiClient from '@/apiClient';

const router = useRouter();
const store = useStore();
const { proxy } = getCurrentInstance();
const toast = useToast();

const curAdmin = store.getters.admin;

const errors = ref({}); 
const groupData = ref([]);
const loading = ref(false);
const groupEditElem = ref({
    editId: 0,
    showDlg: false,

    groupNo: ''
});
const groupStaffElem = ref({
    showDlg: false,
    curGroupId: 0,

    availableStaffs: ref([]),
    selectedStaffs: ref([]),
});





function getDlgGroupBtnText() {
    if(groupEditElem.value.editId > 0) {
        return 'Save Staff Group';
    } else {
        return 'Add Staff Group';
    }
}


function getDlgGroupMainTitle() {
    if(groupEditElem.value.editId > 0) {
        return 'Edit Staff Group';
    } else {
        return 'Add New Staff Group';
    }
}

function setDisabledImgClass(disabled) {
    if(disabled)
        return 'disabledStaffImg';        
}

function getNoDataClass(data) {    
    if(data.length<=0) {
        return 'd-flex justify-content-center';
    } else {
        return 'd-flex justify-content-start';
    }
}


async function handleAddStaffToGroup(groupId) {
    groupStaffElem.value.showDlg = true;
    groupStaffElem.value.curGroupId = groupId;    
    groupStaffElem.value.selectedStaffs = [];
    
    try {
        const response = await apiClient.post('/Staff/getGroupAvailableAddingStaffList', {
            groupId: groupId
        });

        if(response.data) {
            groupStaffElem.value.availableStaffs = response.data.availableStaffs;
        }

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    }
}


function handleAddGroup() {
    groupEditElem.value.editId = 0;
    groupEditElem.value.showDlg = true;

    groupEditElem.value.groupNo = '';
}

function handleEditGroup(id) {
    groupEditElem.value.editId = id;
    groupEditElem.value.showDlg = true;

    const item = groupData.value.find(it => it.dbGroup.id === id);
    groupEditElem.value.groupNo = item?.dbGroup?.groupNo;

}


async function loadGroupData() {
    try {
        const response = await apiClient.post('/Staff/getStaffGroupData');

        if(response.data) {
            groupData.value = response.data.groupData;
        }

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    }
}


async function handleSubmitGroup() {
    // Check inputs
    if(!groupEditElem.value.groupNo) {
        toast.warning('Group number cannot be empty.');
        return;
    }

    loading.value = true;

    try {
        if(groupEditElem.value.editId > 0) {
            const response = await apiClient.post('/Staff/saveStaffGroup', {
                groupId: groupEditElem.value.editId,
                groupNo: groupEditElem.value.groupNo,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }

        } else {
            const response = await apiClient.post('/Staff/addStaffGroup', {
                groupNo: groupEditElem.value.groupNo,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }
        }

        loading.value = false;
        groupEditElem.value.showDlg = false;

        await loadGroupData();

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    } finally {
        loading.value = false;
    }
}


async function handleRemoveGroup(id) {
    if(confirm(`Are you sure you want to remove the group, Id=${id}?`)) {
        try {
            const response = await apiClient.post('/Staff/removeStaffGroup', {
                groupId: id,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }

            await loadGroupData();

        } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
            if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logoutAdmin');
                router.push('/');
            } else {
                toast.error(error.code);                    
            }                                                            
        }
        }
    }
}

async function handleSubmitAddStaffToGroup() {
    // Check inputs    
    if(groupStaffElem.value.selectedStaffs.length<=0) {
        toast.warning('Must select at least one staff.');
        return;
    }

    loading.value = true;

    try {    
        const response = await apiClient.post('/Staff/addStaffToGroup', {
            groupId: groupStaffElem.value.curGroupId,
            staffIds: groupStaffElem.value.selectedStaffs,
        });

        if(response.data) {
            toast.info(response.data.msg);
        }

        await loadGroupData();

        loading.value = false;
        groupStaffElem.value.showDlg = false;
        
    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
            if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logoutAdmin');
                router.push('/');
            } else {
                toast.error(error.code);                    
            }                                                            
        }
    } finally {
        loading.value = false;
    }
}


async function handleRemoveStaffFromGroup(staffId, groupId) {
    if(confirm(`Are you sure you want to remove the staff from current group, Id=${staffId}?`)) {
        try {
            const response = await apiClient.post('/Staff/removeStaffFromGroup', {
                groupId: groupId,
                staffId: staffId,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }

            await loadGroupData();

        } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
            if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logoutAdmin');
                router.push('/');
            } else {
                toast.error(error.code);                    
            }                                                            
        }
        }
    }
}


onMounted(() =>{    

if(!store.getters.isAdminLogin) {
    router.push('/admin');
    return;
} 

loadGroupData();

});

</script>


<style scoped>

.container {
  
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;    
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 30px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;  
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

h4 {
  font-size: 16px;
  font-weight: bold;
}

.links {
  text-align: left;
  width: auto;
}

.links a {  
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 96%;
}

.avatars {
  display: flex;
  align-items: center;
  background: #ccc;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid white;
  margin-left: -10px; 
}

.disabledStaffImg {
  filter: grayscale(100);
}
.disabledStaff {
  color: #CDD0D6;
}
.normalStaff {
  color:#333;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 54%;
  
}

button:hover {
  background-color: #0056b3;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

</style>