<template>
    <div class="row">
   <div class="container">
    <div class="d-flex justify-content-between">
        <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3>         
    </div>
    <hr class="divider-line1" />

    <h2>{{ $t('editAccountTitle') }}</h2>
    <form @submit.prevent="handleSubmit">
      <!-- Name Fields -->
      <div class="row w-100">
        <div class="d-flex justify-content-between data-row">
          <div class="col-6" :class="{'has-error': errors.firstName}">         
            <input
              type="text"
              id="firstName"
              v-model="form.firstName"
              :placeholder="$t('createAccFirstName')"
              @focus="clearError('firstName')"
              required
            />
          </div>
          <div class="col col-6">          
            <input
              type="text"
              id="lastName"
              v-model="form.lastName"
              :placeholder="$t('createAccLastName')"            
            />
          </div>
        </div>       

        <p style="padding-top: 20px;" v-if="errors.firstName" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.firstName }} 
        </p>
      </div>

      <hr class="divider-line" />

       <!-- Phone Number -->
       <div class="row w-100">
        <div class="row data-row" :class="{'has-error': errors.phoneNumber}">        
          <input
            type="tel"
            id="phoneNumber"
            v-model="form.phoneNumber"
            :placeholder="$t('createAccPhoneNo')"
            @focus="clearError('phoneNumber')"                      
          />
        </div>      

        <!-- Error Message -->
        <p v-if="errors.phoneNumber" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.phoneNumber }} 
        </p>
      </div>      

      <!-- Date of Birth -->
      <div class="row w-100">
        <div class="datepicker-container data-row" >            
            <div style=" width:100%; max-width: 560px;" :class="{'has-error': errors.dob}">
            <Datepicker           
              id="dob"
              v-model="form.dob" 
              :format="customFormat" 
              :monday-first="true"  
              @focus="clearError('dob')" 
              :placeholder="$t('createAccDob')"
              style="padding: 10px; width: 100%; max-width: 560px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box; text-align: center;"               
            />        
          </div> 
        </div>

        <p v-if="errors.dob" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.dob }} 
        </p> 
      </div>
     
      <!-- Checkbox for Email Notifications -->
      <div class="row w-100">
        <div class="row checkbox-row">                  
          <label for="emailNotifications" style="width: auto;">
            <input
              type="checkbox"
              id="emailNotifications"
              v-model="form.emailNotifications"
            />
            <span>{{ $t('createAccEmailNotification') }}</span>
          </label>                  
        </div>
      </div>

      <hr class="divider-line" />

      <!-- Submit Button -->
      <div class="row">
        <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">{{ $t('editAccountBtnSave') }}</span>
            <span v-else class="spinner"></span></button>
      </div>
    </form>      
  </div>
</div>
</template>
  
  <script>
  import { ref } from 'vue';
  import apiClient from '@/apiClient.js';
  import { useRouter } from 'vue-router';  
  import { useToast } from 'vue-toastification';
  import { getCurrentInstance } from 'vue';
  import { useStore } from 'vuex';

  
  export default {
    name: "CreateAccount",
    setup() {      
      const router = useRouter();      
      const store = useStore();
      const form = ref({
        firstName: '',
        lastName: '',        
        dob: '',       
        phoneNumber: '',
        emailNotifications: true,        
        });      
      const errors = ref({});
      const loading = ref(false);                  
      const customFormat = 'YYYY-MM-DD';
      const toast = useToast();
      const { proxy } = getCurrentInstance();
      const curUser = store.getters.user;
        
        if(!store.getters.islogin) {
            router.push('/');
        }

        const loadAccountData = async () => {
          try {
            const response = await apiClient.post('/Account/getAccountEditInfo', {
              userId: curUser.userId
            });

            form.value = response.data;
            form.value['dob'] =  new Date(response.data.dob);

          } catch(error) {
              if(error.response && error.response.data && error.response.status == 400) {                    
                  errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
              } else {                
                if(error.response && error.response.status == 401) {
                  toast.error('Your session has expired, please login again.');           
                  store.dispatch('logout');
                  router.push('/');
                } else {
                  toast.error(error.code);                    
                }                                                            
            }
          }
        };

        const handleSubmit = async () => {
        
            loading.value = true;

            console.log('Form Submitted', form.value);            
          
            try {

                const response = await apiClient.post('/Account/saveAccountEditInfo',{
                    userId: curUser.userId,
                    firstName: form.value.firstName,
                    lastName: form.value.lastName,            
                    dob: form.value.dob,                    
                    phoneNumber: form.value.phoneNumber,                  
                    emailNotifications: form.value.emailNotifications                    
                });

                const datas = response.data;
                if(datas) {
                    console.log('Data:', datas);
                }                    

                errors.value = {};

                toast.success(response.data.msg);

                setTimeout(() => {
                  router.push('/account');
                }, 2000);

            } catch(error) {
                console.error('Get account info failed:', error);

                if(error.response && error.response.data && error.response.status == 400) {                    
                    errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
                } else {                
                  if(error.response && error.response.status == 401) {
                    toast.error('Your session has expired, please login again.');           
                    store.dispatch('logout');
                    router.push('/');
                  } else {
                    toast.error(error.code);                    
                  }                                                            
                }

            } finally {
                loading.value = false;
            }
        };

        const clearError = (field) => {
            if(errors.value[field]) {                
              delete errors.value[field];                           
            }            
        };

      return {
        form,
        handleSubmit,                
        errors,
        clearError,
        loading,       
        customFormat,
        loadAccountData,
        curUser,
      };
    },
    mounted() {
      this.loadAccountData();
    }
  };
  </script>
  
  <style scoped>

.data-row {        
  max-width: 560px;
}

  .container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

h2 {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

.row {
  margin-bottom: 15px;
  display: flex;  
  justify-content: center;      
}


.name-row {
  justify-content: center; 
}

.col {
  display: flex;
  flex-direction: column;      
}

.col-6 {
  width: calc(50% - 5px);  
}

.col-6:first-child {
  margin-right: 10px;
}

.col-7 {
  width: 100%;  
  justify-content: center;
  display: flex;
}

.col-validation {
  width: 170px;     
}

.col-validation-text {
  width: calc(50% - 5px);
  max-width: 240px;
}

.col-validation-label {  
  width:100%;
  display: flex;
  justify-content: center;
}


label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="date"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;  
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 54%;
  
}

button:hover {
  background-color: #0056b3;
}

.checkbox-row {
  max-width: 560px;  
  padding: 0px;  
}

.checkbox-row input[type="checkbox"] {
  margin-right: 6px;
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;  
  margin-top: 0px;
  margin-bottom: 30px;
}

.error-message {
  color: #de071c;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-icon {
  margin-right: 5px;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.row.has-error Datepicker {
  border-color: red !important;
  background-color: #fce8e6 !important;
}

.row.has-error input {
  border-color: red;
  background-color: #fce8e6 !important;
}

.col.has-error input {
  border-color: red;
  background-color: #fce8e6 !important;
}

.error-box {
  margin-bottom: 20px; 
  padding: 30px;
  background-color: #fae9a3;
  border: 1px solid #dbc156;
  border-radius: 5px;
  text-align: center;
  color: #333;
  position: relative;
  font-size: 14px;  
  width: auto;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.error-box::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent #ffe9c8 transparent;
}

.datepicker-container {    
  margin-bottom: 20px;
  position: relative;
  display: flex;  
  justify-content: center;    
  width: 100%;
}

.datepicker-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.vue3-datepicker__calendar {
  position: absolute;
  top: 50px; 
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.vue3-datepicker__calendar-header {
  background-color: #f0f0f0;
}

.vue3-datepicker__calendar-day:hover {
  background-color: #007bff;
  color: #fff;
}

</style>
  