<template>    
<div class="container">
    <div class="d-flex justify-content-between links" style="align-items: center;">
        <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3> 
        <a href="#" @click.prevent="backToStep3">{{ $t('orderSummary.linkPreStep') }} ></a>
    </div>

    <hr class="divider-line0"/>

    <div class="row">        
      <h2 style="padding-bottom: 0px;">{{ $t('orderSummary.mainTitle') }}</h2>      
    </div>  

    <div class="row">      
      <div class="summary">
        <div style="text-align: center; margin: 6px;">
          <div style="color:chocolate; font-weight: 600; padding-top: 6px;"> {{ $t(txtCatalog) }} - {{ resultService.name }}</div>
          <div style="font-size: 14px; justify-content: space-between; display: flex; padding: 16px; padding-bottom: 0px;">
            <div style="font-weight: 600;">{{ $t('orderSummary.addrTitle') }}:</div>
            <div style="text-align: left;">
              <span>{{ resultAddress.unit ? resultAddress.unit:'' }} {{ resultAddress.streetNo ? resultAddress.streetNo:'' }} 
                {{ resultAddress.streetName }}<br>{{ resultAddress.city }}, {{ provinceName }} {{ resultAddress.postal }}</span>
            </div>
          </div>

          <div class="d-flex justify-content-center w-100" style="padding: 0px;"><hr class="divider-line1" style="width:94%;"/></div>

          <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding: 16px; padding-bottom: 0px;">
            <div style="font-weight: 600;">{{ $t('orderSummary.timeTitle') }}:</div>
            <div style="text-align: left;">
              {{ $t(WEEKDAYS[onSiteTime.getDay()]) }}, {{ MONTHS[onSiteTime.getMonth()] }}. {{ onSiteTime.getDate() }}, {{ onSiteTime.getFullYear() }}
              <span v-if="catalog === 1"><br>by {{ onSiteTime.getHours() }}:{{ onSiteTime.getMinutes() === 0 ? '00' : onSiteTime.getMinutes() }} {{ slot === 1 ? 'AM' : 'PM' }}</span>
              <span style="font-weight: 600;">&nbsp;MDT</span>
            </div>
          </div>

          <div class="d-flex justify-content-center w-100" style="padding: 0px;"><hr class="divider-line1" style="width:94%;"/></div>

          <div style="font-size: 14px; justify-content: space-between; display: flex; padding: 16px; padding-bottom: 0px;">
            <div style="font-weight: 600;">{{ $t('selSvrDetail.summaryBasePoints') }}: </div>
            <div>
              <span style="color:firebrick">{{ resultService.basePoints }}</span>
            </div>
          </div>
          <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
            <div style="font-weight: 600;">{{ $t('selSvrDetail.summaryAddPoints') }}: </div>
            <div>
              <span style="color:firebrick">{{ resultService.additionalPoints }}</span>
            </div>
          </div>
          <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
            <div style="color:green; font-weight: 600;">{{ $t('selSvrDetail.summaryRankDiscount') }}: </div>
            <div style="color:green;">{{ rankDiscount }}%</div>
          </div>

          <div class="d-flex justify-content-center w-100" style="padding: 0px;"><hr class="divider-line1" style="width:94%;"/></div>

          <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">           
            <div>
            <form @submit.prevent="handleSubmit">
              <div class="button-container">
                <button type="submit" :disabled="loading" class="submit-button">
                  <span v-show="!loading" style="font-size: 13px;">{{ $t('orderSummary.btnSubmit') }}</span>
                  <span v-show="loading" class="spinner"></span>
                </button>            
              </div>
            </form>
            </div>
            <div style="color:firebrick; font-weight: 600; font-size: 17px;">
              {{ $t('selSvrDetail.summaryTotalPoints') }}: {{ resultService.totalPoints }}
              <br><span style="color: #333; font-size: 14px; font-weight: 500;">{{ $t('selSvrDetail.summaryEstimated') }}: ${{(resultService.totalPoints/100).toFixed(1)}}</span>
            </div>
          </div>        
          <div v-show="resultService.specialNotice" style="font-size:14px; text-align: left; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 0px;">
            <div style="color:#007185;"><img src="/misc/notice.png" style="width: 18px"/>&nbsp;{{ $t('selSvrDetail.summaryNotice') }}: {{ resultService.specialNotice }}</div>                          
          </div>
        </div>
      </div>      
    </div>

</div>
</template>



<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; 
import { useToast } from 'vue-toastification';
import { getCurrentInstance,computed } from 'vue';
import { ref,onMounted } from 'vue';
import apiClient from '@/apiClient.js';
import { SERVICECATALOG,PROVINCES,WEEKDAYS,MONTHS } from '@/constants';

const store = useStore();
const router = useRouter();
const { proxy } = getCurrentInstance();
const toast = useToast();
const errors = ref({});
const resultAddress = ref({});
const resultService = ref({});
const rankDiscount = ref('');
const loading = ref(false);   

const curUser = store.getters.user;
  if(!store.getters.islogin) {
      router.push('/');
  }
  
const addressId = Number(router.currentRoute.value.params.addressId);
const catalog = Number(router.currentRoute.value.params.catalog);
const serviceId = Number(router.currentRoute.value.params.serviceId);
const year = Number(router.currentRoute.value.params.yyyy);
const month = Number(router.currentRoute.value.params.mm)-1;
const day = Number(router.currentRoute.value.params.dd);
const slot = Number(router.currentRoute.value.params.slot);
const [hour,minute] = router.currentRoute.value.params.hm.split(":");

const catalogItem = SERVICECATALOG.find(item => item.Id === catalog);        
const txtCatalog = catalogItem ? catalogItem.key : 'emptyKey';

const provinceName = computed(() => {
    const item = PROVINCES.find(item => item.Id === resultAddress.value.provinceId);
    return item ? item.name : '';
  });


const onSiteTime = catalog === 1 ? new Date(year,month,day,hour,minute) : new Date(year,month,day);
if(onSiteTime instanceof Date && isNaN(onSiteTime)) {
    toast.error('On-site time is invalid.');
    setTimeout(() => {        
        router.push({ name: 'selectServiceTime', params: { catalog: catalog, addressId: addressId, serviceId: serviceId }});
    }, 1000);
}

function backToStep3() {
    router.push({ name: 'selectServiceTime', params: { catalog: catalog, addressId: addressId, serviceId: serviceId }});
}


async function loadServiceDetail() {
  
  try {
    const response = await apiClient.post('/Service/getUserAvailableServiceTime', {
      userId: curUser.userId,
      serviceCatalog: catalog,
      addressId: addressId,
      serviceId: serviceId
    });
  
    resultAddress.value = response.data.contactInfo;  
    resultService.value = response.data.service;
    rankDiscount.value = response.data.rankDiscount;    
  
  } catch(error) {
      if(error.response && error.response.data && error.response.status == 400) {                    
              errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
          } else {                
            if(error.response && error.response.status == 401) {
              toast.error('Your session has expired, please login again.');           
              store.dispatch('logout');
              router.push('/');
            } else {
              toast.error(error.code);                    
            }                                                            
      }
  }
}


async function handleSubmit() {

  loading.value = true;
  const newOrderNo = ref('');
  const createTime = ref('');

  try {    
    const response = await apiClient.post('/Service/createServiceOrder', {
      userId: curUser.userId,
      addressId: addressId,
      serviceId: serviceId,
      serviceCatalog: catalog,
      year: year,
      month: month+1,
      day: day,
      slot: slot,
      hour: hour,
      minute: minute,
      basePoints: resultService.value.basePoints,
      additionalPoints: resultService.value.additionalPoints,
      rankDiscount: rankDiscount.value
    });    
    
    if(!response.data) {    
      toast.error('Create order error, please try again.');
      loading.value = false;
      return;
    }

    newOrderNo.value = response.data.newOrderNo;
    createTime.value = response.data.createTime;

    setTimeout(() => {
    loading.value = false;    

    router.push({name:'userOrderCreated', params:{
       orderNo: newOrderNo.value,
       onsiteTime: onSiteTime,
       serviceCatalog: catalog,
       serviceName: resultService.value.name,
       timeSlot: slot
      }});

  }, 200);

  } catch(error) {
    if(error.response && error.response.data && error.response.status === 400) {                    
        errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg, errors);                    
    }
    else {                    
      if(error.response && error.response.status === 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logout');
        router.push('/');
      } else {
        toast.error(error.code);                    
      }                            
    }
  } finally {
    loading.value = false;
  } 
}


onMounted(() =>{    
    loadServiceDetail();

  });

</script>

<style scoped>

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
  padding-top: 10px;
  padding-bottom: 10px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;         
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
  margin-top: 16px;
}

.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 94%;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;  
}

button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  width: 140px;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.button-container {  
  width: 100%;  
}

</style>