<template>     
  <div class="row">
    
  <header class="hero">                       
      <div class="hero-content" >
          <h1>{{ $t('companyName') }}</h1>
          <!--<p>Website is under construction, stay tuned...</p>
          <p>Every second counts, and we want to provide an affordable way to save the time of every busy family!</p>-->
          <p>{{ $t('serviceMessage') }}</p>            
          <a href="#services" class="btn">{{ $t('BookText') }}</a>
      </div>     
  </header> 
</div>

<section id="services" class="services-section">
  <div class="row">  
    <h2>{{ $t('serviceTitle') }}</h2>    
    <div class="col-12 col-md-6 col-lg-3 mb-4">
    <div class="card h-100">
      <img src="../assets/service_housecleaning.jpg" class="card-img-top img-fluid" :alt="$t(catalogCleaning.key)">
      <div class="card-body">
        <h3 class="card-title" style="color: #4CAF50; margin-bottom: 15px">{{ $t(catalogCleaning.key) }}</h3>       
        <p class="card-text" style="font-size: 1.0rem; line-height: 1.8;">{{ $t('houseCleaningText') }}</p>
        <a href="#" class="btn btn-primary" @click.prevent="checkAccountToBook(1)">{{ $t('serviceBtnBook') }}</a>&nbsp;&nbsp;
        <a href="#" class="btn btn-primary">{{ $t('serviceBtnExplore') }}</a>
      </div>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3 mb-4">
    <div class="card h-100">
      <img src="../assets/service_snowremoval.jpg" class="card-img-top img-fluid" :alt="$t(catalogSnowRemoval.key)">
      <div class="card-body">
        <h3 class="card-title" style="color: #4CAF50; margin-bottom: 15px">{{ $t(catalogSnowRemoval.key) }}</h3>       
        <p class="card-text" style="font-size: 1.0rem; line-height: 1.8;">{{ $t('snowRemovalText') }}</p>
        <a href="#" class="btn btn-primary"  @click.prevent="checkAccountToBook(3)">{{ $t('serviceBtnBook') }}</a>&nbsp;&nbsp;
        <a href="#" class="btn btn-primary">{{ $t('serviceBtnExplore') }}</a>
      </div>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3 mb-4">
    <div class="card h-100">
      <img src="../assets/service_lawncare.jpg" class="card-img-top img-fluid" :alt="$t(catalogLawnCare.key)">
      <div class="card-body">
        <h3 class="card-title" style="color: #4CAF50; margin-bottom: 15px">{{ $t(catalogLawnCare.key) }}</h3>       
        <p class="card-text" style="font-size: 1.0rem; line-height: 1.8;">{{ $t('lawnCareText') }}</p>
        <a href="#" class="btn btn-primary" @click.prevent="checkAccountToBook(2)">{{ $t('serviceBtnBook') }}</a>&nbsp;&nbsp;
        <a href="#" class="btn btn-primary">{{ $t('serviceBtnExplore') }}</a>
      </div>
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3 mb-4">
    <div class="card h-100">
      <img src="../assets/service_andmanymore.jpg" class="card-img-top img-fluid" :alt="$t('andAnyMoreTitle')">
      <div class="card-body">
        <h3 class="card-title" style="color: #4CAF50; margin-bottom: 15px">{{ $t('andAnyMoreTitle') }}</h3>       
        <p class="card-text" style="font-size: 1.0rem; line-height: 1.8;">{{ $t('andAnyMoreText') }}</p> 
        <a href="#" class="btn btn-primary">{{ $t('serviceBtnExplore') }}</a>
      </div>
    </div>
    </div>
  </div>
</section>

  <div class="row">
    <section id="about" class="about-section">
        <h2>{{ $t('aboutUsTitle') }}</h2>       
        <p>{{ $t('aboutUsText1') }}</p> 
        <p>{{ $t('aboutUsText2') }}</p>        
    </section>
  </div>

  <div class="row">
    <section id="testimonials" class="testimonials-section">
        <h2>{{ $t('testimonialsTitle') }}</h2>
        <div class="testimonial">
            <p>"Chen Home Tech has been a lifesaver for my lawn care needs. Highly recommended!"</p>
            <cite>- John Doe</cite>
        </div>
        <div class="testimonial">
            <p>"The snow removal service is top-notch and very reliable."</p>
            <cite>- Jane Smith</cite>
        </div>
    </section>
  </div>
</template>

<script>
import apiClient from '@/apiClient.js';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { getCurrentInstance } from 'vue';
import { SERVICECATALOG } from '@/constants';


export default {
  name: 'HelloWorld',
  setup() {
    const store = useStore();
    const router = useRouter();
    const errors = ref({});
    const { proxy } = getCurrentInstance();
    const toast = useToast();

    const curUser = store.getters.user;

    const catalogCleaning = proxy.$getConstantItem(1, SERVICECATALOG);    
    const catalogSnowRemoval = proxy.$getConstantItem(3, SERVICECATALOG);
    const catalogLawnCare = proxy.$getConstantItem(2, SERVICECATALOG);

    const checkAccountToBook = async (catalog) => {          
      if(store.getters.islogin) {

        try {
        
          const response = await apiClient.post('/Service/getRecentOrdersByCatalog', {
            userId: curUser.userId,
            catalog: catalog,
          }); 

          if(response.data.orders && response.data.orders.length>0) {
            setTimeout(() => { router.push('/account');  }, 200);                       
          } else {
            setTimeout(() => {
              router.push({name:'selectServiceAddress', params:{ catalog: catalog }});          
            }, 200);            
          }        

        } catch(error) {
            if(error.response && error.response.data && error.response.status == 400) {                    
                errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
            } else {                
              if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logout');
                router.push('/');
              } else {
                toast.error(error.code);                    
              }                                                            
            }
        }       
      } 
      else {
        router.push('/login');        
      }        
    };


    return {
      checkAccountToBook, 
      catalogCleaning,
      catalogSnowRemoval,
      catalogLawnCare,
    };
  }
};



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>

#language-selector {
  margin: 10px;
  padding: 5px;
  font-size: 16px;
}
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
}
</style>
