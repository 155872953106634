import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import AdminLayout  from '@/layouts/AdminLayout.vue';
import HelloWorld from '../components/HelloWorld.vue';
import AboutPage from '../components/AboutPage.vue';
import LoginPage from '../components/LoginPage.vue';
import CreateAccount from '../components/UserAccount/CreateAccount.vue';
import AccountPage from '../components/UserAccount/AccountPage.vue';
import PasswordReset1 from '../components/UserAccount/PasswordResetStep1.vue';
import PasswordReset2 from '../components/UserAccount/PasswordResetStep2.vue';
import ServiceDetail from '../components/ServiceDetail.vue';
import SelectServiceAddress from '@/components/Service/SelectServiceAddress.vue';
import RankDetail from '@/components/RankDetail.vue';
import UserAddressDetail from '@/components/UserAddress/UserAddressDetail.vue';
import AddUserAddress from '@/components/UserAddress/AddUserAddress.vue';
import EditUserAddress from '@/components/UserAddress/EditUserAddress.vue';
import EditAccount from '@/components/UserAccount/EditAccount.vue';
import ChangePassword from '@/components/UserAccount/ChangePassword.vue';
import SelectServiceDetail from '@/components/Service/SelectServiceDetail.vue';
import SelectServiceTime from '@/components/Service/SelectServiceTime.vue';
import ServiceOrderSummary from '@/components/Service/ServiceOrderSummary.vue';
import UserOrderList from '@/components/UserAccount/UserOrderList.vue';
import UserOrderDetail from '@/components/UserAccount/UserOrderDetail.vue';
import UserOrderCreated from '@/components/UserAccount/UserOrderCreated.vue';
import StaffLogin from '@/components/Staff/StaffLogin.vue';
import StaffMainPage from '@/components/Staff/StaffMainPage.vue';
import AdminEntry from '@/components/Staff/AdminEntry.vue';
import AdminMainPage from '@/components/Staff/AdminMainPage.vue';
import AdminServiceList from '@/components/Staff/AdminServiceList.vue';
import AdminStaffList from '@/components/Staff/AdminStaffList.vue';
import AdminStaffGroupList from '@/components/Staff/AdminStaffGroupList.vue';
import AdminOrderList from '@/components/Staff/AdminOrderList.vue';


const routes = [    
    {
      path: '/',
      component: DefaultLayout,
      children: [
        {
          path: '/',
          name: 'Home',
          component: HelloWorld,
        },
        {
          path: '/about',
          name: 'About',
          component: AboutPage,
        },
        {
          path: '/login',
          name: 'Login',
          component: LoginPage,
        },
        {
          path: '/account',
          name: 'Account',
          component: AccountPage,
        },     
        {
          path: '/createAccount',
          name: 'CreateAccount',
          component: CreateAccount,
        },
       
        {
          path: '/passwordReset1',
          name: 'PasswordReset1',
          component: PasswordReset1,
        },
        {
          path: '/passwordReset2',
          name: 'PasswordReset2',
          component: PasswordReset2,
        },
        {
          path: '/serviceDetail',
          name: 'serviceDetail',
          component: ServiceDetail,
        },
        {
          path: '/selectServiceAddress/:catalog',
          name: 'selectServiceAddress',
          component: SelectServiceAddress,
        },
        {
          path: '/selectServiceDetail/:catalog/:addressId',
          name: 'selectServiceDetail',
          component: SelectServiceDetail,
        },
        {
          path: '/selectServiceTime/:catalog/:addressId/:serviceId',
          name: 'selectServiceTime',
          component: SelectServiceTime,
        },
        {
          path: '/serviceOrderSummary/:catalog/:addressId/:serviceId/:yyyy/:mm/:dd/:slot/:hm',
          name: 'serviceOrderSummary',
          component: ServiceOrderSummary,
        },    
        {
          path: '/rankDetail',
          name: 'rankDetail',
          component: RankDetail,
        },
        {
          path: '/userAddressDetail',
          name: 'userAddressDetail',
          component: UserAddressDetail,
        },
        {
          path: '/addUserAddress',
          name: 'addUserAddress',
          component: AddUserAddress,
        },
        {
          path: '/editUserAddress/:addressId',
          name: 'editUserAddress',
          component: EditUserAddress,
        },
        {
          path: '/editAccount',
          name: 'editAccount',
          component: EditAccount,
        },
        {
          path: '/changePassword',
          name: 'changePassword',
          component: ChangePassword,
        },
        {
          path: '/userOrderList/:catalog',
          name: 'userOrderList',
          component: UserOrderList,
        },
        {
          path: '/userOrderDetail/:orderNo',
          name: 'userOrderDetail',
          component: UserOrderDetail,
          props: true,
        },
        {
          path: '/userOrderCreated/:orderNo/:onsiteTime/:serviceCatalog/:serviceName/:timeSlot',
          name: 'userOrderCreated',
          component: UserOrderCreated,
        },      
      ],
    },      
    {
      path: '/admin',
      component: AdminLayout,
      children: [        
        {
          path: '/admin',
          name: 'AdminEntry',
          component: AdminEntry,      
        },
        {
          path: '/adminMainPage',
          name: 'AdminMainPage',
          component: AdminMainPage,
        },
        {
          path: '/adminServiceList',
          name: 'AdminServiceList',
          component: AdminServiceList,
        },     
        {
          path: '/adminStaffList',
          name: 'AdminStaffList',
          component: AdminStaffList,
        },
        {
          path: '/adminStaffGroupList',
          name: 'AdminStaffGroupList',
          component: AdminStaffGroupList,
        },
        {
          path: '/adminOrderList',
          name: 'AdminOrderList',
          component: AdminOrderList,
        }
      ]
    },
    
    
    {
      path: '/staffLogin',
      name: 'staffLogin',
      component: StaffLogin,      
    },
    {
      path: '/staffMainPage',
      name: 'staffMainPage',
      component: StaffMainPage,      
    },
            
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
