<template> 

<div class="container">
    <h1>Welcome, Chen </h1>
    <form @submit.prevent="">
      <div class="form-group">
        <label for="rooms">Number of Rooms</label>
        <input type="number" id="rooms"  required />
      </div>
      <div class="form-group">
        <label for="bathrooms">Number of Bathrooms</label>
        <input type="number" id="bathrooms" required />
      </div>
      <button type="submit">Next</button>
    </form>
  </div>

</template>


<script>
import { ref } from 'vue';
import apiClient from '@/apiClient.js';
import { useRouter } from 'vue-router';  
import { useToast } from 'vue-toastification';
import { getCurrentInstance } from 'vue';


export default {
    name: "ServiceDetail",
    setup() {
        const toast = useToast();
        const { proxy } = getCurrentInstance();
        const errors = ref({});
        const router = useRouter();
        const data = ref('');

        const catalog = localStorage.getItem('serviceCatalog');        
        console.log('Catalog=',catalog);
        
        const loadServiceData = async () => {
            try {

                const response = await apiClient.get('/Service/getServiceDetailList?catalog='+ catalog);
                
                data.value = response.data.msg;

            } catch(error) {
                if(error.response && error.response.data && error.response.status==400) {                    
                    errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
                }
                else {                    
                    toast.error(error.code);
                }
            }

        };

        return {
            loadServiceData,
            errors,
            data,

            router,
        };
    },
    mounted() {
        this.loadServiceData();
    }
};

</script>


<style scoped>



</style>