<template>    
  <div class="container">
     <div class="d-flex justify-content-between links" style="align-items: center;">
         <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3>  
         <a href="#" @click.prevent="backToOngointOrder">{{ $t('orderCreated.linkOrders') }} ></a>       
     </div>
 
     <hr class="divider-line0"/>
 
     <div class="row">
      <div class="d-flex justify-content-start">        
        <div><img src="/misc/greenhook.png" style="width: 20px; height: 20px;"/></div>
        <div><h2 style="padding-bottom: 0px; color: #0aa06e;">&nbsp;{{ $t('orderCreated.completedTitle') }}</h2></div>      
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 6px; padding-bottom: 6px;">
        {{ $t('orderCreated.confirmationTip') }}
      </div>
      <div style="padding-top: 10px;" class="d-flex justify-content-start">        
        <div>
          <p style="color:#333; font-weight: 600;">{{ $t('orderCreated.orderNoTitle') }}# {{ orderNo }}</p>
        </div>       
      </div>
      <hr class="divider-line0"/>
      <div class="d-flex justify-content-start" style="padding-top: 10px; font-weight: 600;">
        {{ $t(WEEKDAYS[onSiteTime.getDay()]) }}, {{ MONTHS[onSiteTime.getMonth()] }}. {{ onSiteTime.getDate() }}, {{ onSiteTime.getFullYear() }} 
        <span v-if="catalog === 1">&nbsp;by {{ onSiteTime.getHours() }}:{{ onSiteTime.getMinutes() === 0 ? '00' : onSiteTime.getMinutes() }} {{ slot === 1 ? 'AM' : 'PM' }}</span>
        <span style="font-weight: 600;">&nbsp;MDT</span>
      </div>
      <div class="d-flex justify-content-start">
        <p style="color:chocolate; font-size: 22px; font-weight: 600;">{{ $t(txtCatalog) }} - {{ serviceName }}</p>
      </div>            
      <div class="d-flex justify-content-start">
        {{ $t('orderCreated.otherTip') }}
      </div>
    </div>

    <div class="row links" style="padding-bottom: 30px; padding-top: 20px;">
      <a href="#" @click.prevent="viewOrderDetails" style="width:auto;">{{ $t('orderCreated.linkViewOrderDetails') }} ></a>
    </div>

  </div>
 </template>
 
 <script setup>
 import { useStore } from 'vuex';
 import { useRouter } from 'vue-router'; 
 import { WEEKDAYS,MONTHS,SERVICECATALOG } from '@/constants';
 
 const store = useStore();
 const router = useRouter();
 
 const curUser = store.getters.user;
   if(!store.getters.islogin) {
       router.push('/');
   }
 
   const orderNo = router.currentRoute.value.params.orderNo;
   const onSiteTime = new Date(router.currentRoute.value.params.onsiteTime);
   const catalog = Number(router.currentRoute.value.params.serviceCatalog);
   const serviceName = router.currentRoute.value.params.serviceName;
   const slot = router.currentRoute.value.params.timeSlot;
 
   const catalogItem = SERVICECATALOG.find(item => item.Id === catalog);        
   const txtCatalog = catalogItem ? catalogItem.key : 'emptyKey';

   function backToOngointOrder() {
    router.push({name: 'userOrderList', params: {
      catalog: 0
    }, query: { posOrderNo: orderNo }});
   }

   function viewOrderDetails() {
    router.push({name:'userOrderDetail', params:{ orderNo: orderNo }});
   }


 </script>
 
 <style scoped>
 
 .container {
   max-width: 1280px;
   margin: 0 auto;
   padding: 20px;
   font-family: Arial, sans-serif;  
 }
 
 h2 { 
   font-weight: 600;
   font-size: 22px;
   color: #333;          
   margin-top: 2px;
 }
 
 h3 {   
   margin-bottom: 14px;  
   padding-top: 10px;
   font-weight: 500;
   font-size: 20px;
   color: #333;            
 }
 
 .divider-line {
   border: none;
   border-top: 1px solid #ccc;
   margin: 30px 0;
 }
 
 .divider-line0 {
   border: none;
   border-top: 1px solid #ccc;
   margin: 0px 0;
   margin-bottom: 10px;
 }
 
 .divider-line1 {
   border: none;
   border-top: 1px solid #ccc;
   margin: 0px 0;
   margin-bottom: 10px;
   margin-top: 16px;
 }

 .links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}
 
 </style>
     