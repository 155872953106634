<template>
    <div class="row">
   <div class="container">
    <h2>{{ $t('resetPasswordTitle') }}</h2>
    <form @submit.prevent="handleSubmit">
      
      <!-- Email or Password -->
      <div class="row" :class="{'has-error': errors.validationCode}">
        <input
            type="text"
            id="validationCode"
            v-model="form.validationCode"
            :placeholder="$t('resetPasswordValidationCodeHold')"
            @focus="clearError('validationCode')"
            required
          />
      </div>
      <p v-if="errors.validationCode" class="error-message">
        <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.validationCode }} 
      </p>
     
      <hr class="divider-line" />            

      <div class="row" :class="{'has-error': errors.newPassword}">        
        <input
          type="password"
          id="newPassword"
          v-model="form.newPassword"
          :placeholder="$t('resetPasswordNewPasswordHold')"
          @focus="clearError('newPassword')"
          required
        />
      </div>
      <div class="row" :class="{'has-error': errors.confirmPassword}">        
        <input
          type="password"
          id="confirmPassword"
          v-model="form.confirmPassword"
          :placeholder="$t('createAccConfirmPassword')"
          @focus="clearError('confirmPassword')"
          required
        />        
      </div>     
      <!-- Error Message -->
      <p v-if="errors.newPassword || errors.confirmPassword" class="error-message">
        <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.newPassword || errors.confirmPassword }} 
      </p>      

      <hr class="divider-line" />

      <!-- Submit Button -->
      <div class="row">
        <button type="submit" :disabled="loading" class="submit-button" style="width: 40%"><span v-if="!loading">{{ $t('resetPasswordSubmit') }}</span>
            <span v-else class="spinner"></span>
        </button>
      </div>
    </form>      
  </div>
</div>
</template>

<script>

import { ref } from 'vue';
import apiClient from '@/apiClient.js';
import { useRouter } from 'vue-router';  
import { useToast } from 'vue-toastification';
import { getCurrentInstance } from 'vue';


export default {
    name: "PasswordReset2",
    setup() {        
        const router = useRouter();
        const errors = ref({});
        const loading = ref(false);     
        const { proxy } = getCurrentInstance();
        const toast = useToast();

        const form = ref({       
            newPassword: '',        
            validationCode: '',
        });

        // Get userId of resetting user.
        const userId = localStorage.getItem('resetPwdUserId',);
        if(userId <= 0) {
            router.push('/');
        }

        const handleSubmit = async () => {
            loading.value = true;

            console.log('Form Submitted', form.value);

            try {

                // Check the validity of local password.
                if(form.value.confirmPassword != form.value.newPassword) {
                errors.value['confirmPassword'] = 'The passwords you entered do not match.';
                errors.value['newPassword'] = 'The passwords you entered do not match.';                
                loading.value = false;
                return;
                }
                else if(form.value.newPassword.length<6) {
                    errors.value['confirmPassword'] = 'The password your entered does not meet the minimum length requirement.';
                    errors.value['newPassword'] = 'The password your entered does not meet the minimum length requirement.';                
                    loading.value = false;
                    return;
                }

                

                const response = await apiClient.post('/Account/resetAccountPassword',{                    
                    newPassword: form.value.newPassword,              
                    validationCode: form.value.validationCode,
                    userId: userId,
                });

                errors.value = {};
                localStorage.removeItem('resetPwdUserId');

                toast.success(response.data.msg);

                setTimeout(() => {
                    router.push('/login');
                }, 2000);

            } catch(error) {
                console.error('Reset account password failed:', error);

                if(error.response && error.response.data && error.response.status==400) {                    
                    errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
                }
                else {                    
                    toast.error(error.code);
                }
            } finally {
                loading.value = false;
            }

        };

        const navigateToPage = (path) => {
            router.push(path);
        };

        const clearError = (field) => {
            if(errors.value[field]) {
                delete errors.value[field];                           
            }            
        };


        return {          
          form,          
          errors,          
          loading,
          navigateToPage,         
          clearError,          
          handleSubmit,
        };
    }
};

</script>

<style scoped>

.container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

h2 {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;
}

.row {
  margin-bottom: 15px;
  display: flex;  
  justify-content: center;      
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

input[type="text"],
input[type="password"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 560px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 54%;  
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #de071c;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-icon {
  margin-right: 5px;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.row.has-error input {
  border-color: red;
  background-color: #fce8e6 !important;
}


</style>
