<template>    
<div class="row">
    <div class="container">
        <div class="row">
            <div class="d-flex justify-content-between">
                <div>
                    <h3>Hi, {{ curAdmin?.userName }}.</h3> 
                </div>                    
            </div>

            <hr class="divider-line0" />
    
            <h2>Service Item Settings</h2>
        </div>

        <div class="row" style="padding-bottom: 30px;">
            <el-tabs v-model="activeCatalog" tab-position="left" class="demo-tabs"  @tab-click="handleClick">                
                <el-tab-pane v-for="i in catalogCount" :key="i" :label="$t(getTxtCatalog(i))" :name="i">
                    <div style="padding-left: 20px;">
                        <div>
                            <h4>Additional Items</h4>
                        </div>
                        <div v-if="itemData && itemData.length<=0">No additional item data</div>
                        <div v-for="item in itemData" :key="item?.id" class="summary">
                            <div class="d-flex justify-content-start" style="padding: 10px; padding-left: 16px; font-size: 14px; color:#333;">
                                <div style="font-weight: 600;">
                                   Id#  {{ item?.id }}
                                </div>                                                  
                                <div style="padding-left: 10px;">
                                    <el-tooltip :content="'#'+item?.condType">{{ getPropertyCond(item?.condType) }}</el-tooltip> 
                                    - 
                                    <el-tooltip :content="'#'+item?.propertyAttr">{{ $t(getPropertyAttr(item?.condType, item?.propertyAttr)) }}</el-tooltip>
                                </div>                            
                            </div>
                            <div><hr class="divider-line1" /></div>
                            <div class="d-flex justify-content-between" style="padding: 10px; padding-left: 16px; font-size: 14px; color:#333;">
                                <div style="font-weight: 600;" :style="getWorkloadColor(item?.countingType)" >
                                    {{ getCountingType(item?.countingType) }}: {{ item?.consuming }}{{ getWorkloadPostfixText(item?.countingType) }}
                                </div>
                                <div style="padding-right: 10px;" class="links">
                                    <a href="#" @click.prevent="handleEditAdditionalItem(item?.id)">Edit</a> <span style="color:darkgray">|</span> <a href="#" @click.prevent="handleRemoveAdditionalItem(item?.id)">Remove</a>
                                </div>
                            </div>                            
                        </div>
                        <div class="d-flex justify-content-end" style="padding-right: 40px; padding-top: 10px;">                            
                            <div class="links">                            
                                <a href="#" @click.prevent="handleAddAdditionalItem">Add new additional item</a>
                                <el-dialog v-model="dlgAddAdditionalItem" width="600" destroy-on-close>
                                    <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                                        <span style="color:333; font-size: 16px;; font-weight: 600;">{{ getDlgMainTitle() }}</span>
                                    </div>
                                    <hr class="divider-line1"/>
                                    <div class="d-flex justify-content-between" style="padding-top: 20px;">
                                        <div>Property condition type/attribute:
                                        <el-cascader
                                        v-model="condType"
                                        :options="propertyCondOptions"                                       
                                        style="width: 300px;"
                                         @change="handleChange"
                                        />
                                        </div>                                        
                                    </div>
                                    <div class="d-flex justify-content-center" style="padding-top: 20px; align-items: center;">
                                        <div>                                            
                                            <select id="countType" v-model="countType">
                                                <option disabled value="0" style="color:darkgrey">Counting type</option>      
                                                <option v-for="item in COUNTINGTYPES" :key="item.Id" :value="item.Id">
                                                    {{ item.name }}
                                                </option>          
                                            </select>
                                        </div>
                                        <div>
                                            <el-input-number v-model="countingNum" :min="1" :max="500" class="mx-4"/>
                                        </div>
                                    </div>                                    
                                    <form @submit.prevent="handleSubmitAddAdditionalItem">
                                    <div class="d-flex justify-content-center" style="padding-top: 30px; padding-bottom: 30px; align-items: center;">                                        
                                        <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">{{ getDlgBtnText() }}</span>
                                        <span v-else class="spinner"></span>
                                        </button>                                        
                                    </div>
                                    </form>
                                </el-dialog>
                            </div>
                            
                        </div>
                        <hr class="divider-line" />                        

                        <div>
                            <h4>Service Items</h4>
                        </div>
                        <div v-if="serviceItemData && serviceItemData.length<=0">No service item data</div>
                        <div v-for="serviceItem in serviceItemData" :key="serviceItem?.serviceId" class="summary">
                            <div class="d-flex justify-content-start" style="padding: 10px; padding-left: 16px; font-size: 14px;" :style="getServiceItemDisableColor(serviceItem?.disabled)">
                                <div style="font-weight: 600;">
                                   Id#  {{ serviceItem?.serviceId }}
                                </div>
                                <div style="padding-left: 10px;">
                                    {{ serviceItem?.name }}
                                </div>
                            </div>
                            <div><hr class="divider-line1" /></div>
                            <div class="d-flex justify-content-start" style="padding: 10px; padding-left: 16px; font-size: 14px;" :style="getServiceItemDisableColor(serviceItem?.disabled)">
                                <div v-if="!serviceItem?.description">No description</div>
                                <div v-else>{{ serviceItem?.description }}</div>
                            </div>
                            <div class="d-flex justify-content-start" style="padding: 10px; padding-left: 16px; font-size: 14px; color:#333;">
                                <div>
                                    <el-checkbox v-model="serviceItem.disabled" label="Disabled" disabled/>
                                    <el-checkbox v-model="serviceItem.noCalc" label="No calc" disabled/>
                                </div>
                            </div>       
                            <div><hr class="divider-line1" /></div>
                            <div class="d-flex justify-content-start" style="padding: 10px; padding-bottom: 0px; padding-left: 16px; font-size: 14px;" :style="getServiceItemDisableColor(serviceItem?.disabled)">
                                <div style="font-size: 16px; font-weight: 600;">Check Point Items</div>
                            </div>                
                            <div class="d-flex justify-content-start" style="padding: 10px; padding-top: 0px; padding-left: 16px; font-size: 14px;" :style="getServiceItemDisableColor(serviceItem?.disabled)">
                                <div class="row">
                                <div v-if="serviceItem.checkPointItems && serviceItem.checkPointItems.length<=0">No check point item data</div>
                                <div v-for="checkPointItem in serviceItem.checkPointItems" :key="checkPointItem?.id" class="summarySub">
                                    <div class="d-flex justify-content-start" style="padding: 10px; padding-left: 16px; font-size: 14px;" :style="getServiceItemDisableColor(serviceItem?.disabled)">
                                        <div style="font-weight: 600;">
                                            Id# {{ checkPointItem?.id }}
                                        </div>
                                        <div style="padding-left: 10px;">
                                            {{ checkPointItem?.itemName }}
                                        </div>
                                    </div>
                                    <div><hr class="divider-line1" /></div>
                                    <div class="d-flex justify-content-between" style="padding: 10px; padding-left: 16px; font-size: 14px;" :style="getServiceItemDisableColor(serviceItem?.disabled)">
                                        <div style="color:firebrick; font-weight: 600;">
                                            <span style="color:#333">Base:</span> {{ checkPointItem?.timeConsuming }}mins
                                        </div>                                        
                                    </div>
                                    <div v-if="checkPointItem.extraCountingType > 0" class="d-flex justify-content-between" style="padding: 10px; padding-top: 0px; padding-left: 16px; font-size: 14px;" :style="getServiceItemDisableColor(serviceItem?.disabled)">
                                        <div style="font-weight: 600;" :style="getWorkloadColor(checkPointItem?.extraCountingType)">
                                            <span style="color:#333">Extra:</span> {{ getCountingType(checkPointItem?.extraCountingType) }} {{ checkPointItem?.extraConsuming }}{{ getWorkloadPostfixText(checkPointItem?.countingType) }}
                                        </div>
                                    </div>
                                    <div v-else>&nbsp;</div>
                                    <div v-if="!serviceItem.disabled" class="d-flex justify-content-end" style="padding: 10px;font-size: 14px;">
                                        <div style="padding-right: 10px;" class="links">
                                            <a href="#" @click.prevent="handleEditCheckPointItem(checkPointItem?.id, serviceItem?.serviceId)">Edit</a> <span style="color:darkgray">|</span> <a href="#" @click.prevent="handleRemoveCheckPointItem(checkPointItem?.id, serviceItem?.serviceId)">Remove</a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>   
                            <div class="d-flex justify-content-end" style="padding: 10px; padding-bottom: 20px; padding-left: 16px; font-size: 14px;" :style="getServiceItemDisableColor(serviceItem?.disabled)">
                                <div class="links" style="padding-right: 40px;" v-if="!serviceItem.disabled">
                                    <a href="#" @click.prevent="handleAddCheckPointItem(serviceItem?.serviceId)">Add new check point item</a>                                        
                                </div>
                                <div style="padding-right: 10px;" class="links">                                    
                                    <a href="#" @click.prevent="handleEditServiceItem(serviceItem?.serviceId)">Edit</a> <span style="color:darkgray" v-if="serviceItem?.checkPointItems.length<=0">|</span> <a href="#" v-if="serviceItem?.checkPointItems.length<=0" @click.prevent="handleRemoveServiceItem(serviceItem?.serviceId)">Remove</a>
                                </div>
                            </div>                            
                        </div>
                        <div class="d-flex justify-content-end" style="padding-right: 40px; padding-top: 10px;">
                            <div class="links">
                                <a href="#" @click.prevent="handleAddServiceItem">Add new service item</a>
                                
                            </div>
                        </div> 
                    </div>
                </el-tab-pane>                          
            </el-tabs>
        </div>

        <el-dialog v-model="dlgServiceItem" width="600" destroy-on-close>
            <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                <span style="color:333; font-size: 16px;; font-weight: 600;">{{ getDlgServiceItemMainTitle() }}</span>
            </div>
            <hr class="divider-line1"/>
            <div class="d-flex justify-content-center" style="padding-top: 20px;">
                <div>
                    <el-input
                    v-model="serviceItemName"
                    style="width: 360px"
                    placeholder="Item name"
                    />
                </div>                                        
            </div>
            <div class="d-flex justify-content-center" style="padding-top: 10px;">
                <div>
                    <el-input
                    v-model="serviceItemDescription"
                    style="width: 360px"
                    :autosize="{ minRows: 3, maxRows: 5 }"
                    type="textarea"
                    placeholder="Item description"
                    />
                </div>
            </div>

            <div class="d-flex justify-content-center" style="padding-top: 10px;">
                <el-checkbox v-model="serviceItemDisabled" label="Disabled"/>
                <el-checkbox v-model="serviceItemNoCalc" label="No calc"/>
            </div>
            <form @submit.prevent="handleSubmitServiceItem">
                <div class="d-flex justify-content-center" style="padding-top: 30px; padding-bottom: 30px; align-items: center;">                                        
                    <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">{{ getDlgServiceItemBtnText() }}</span>
                    <span v-else class="spinner"></span>
                    </button>                                        
                </div>
            </form>
        </el-dialog>

        <el-dialog v-model="dlgCheckPointItem" width="600" destroy-on-close>
            <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                <span style="color:333; font-size: 16px;; font-weight: 600;">{{ getDlgCheckPointItemMainTitle() }}</span>
            </div>
            <hr class="divider-line1"/>
            <div class="d-flex justify-content-center" style="padding-top: 20px;">
                <el-input
                v-model="checkPointItemName"
                style="width: 300px"
                placeholder="Item name"
                />
            </div>
            <div class="d-flex justify-content-center" style="padding-top: 10px; align-items: center;">
                <div style="font-weight: 600; padding-right: 0px;">Base:</div>
                <div style="padding-left: 0px;"><el-input-number v-model="checkPointItemBaseMin" :min="1" :max="500" class="mx-4"/></div>
            </div>
            <div class="d-flex justify-content-center" style="padding-top: 20px; align-items: center;">
                <div style="font-weight: 600; padding-right: 10px;"><el-checkbox v-model="checkPointItemEnableExtra" label="Enable Extra" style="font-weight: 600;"/></div>                
            </div>
            <div v-if="checkPointItemEnableExtra" class="d-flex justify-content-center" style="padding-top: 6px; align-items: center;">
                <div>
                    <select id="countType" v-model="checkPointItemCountingType">
                        <option disabled value="0" style="color:darkgrey">Counting type</option>      
                        <option v-for="item in COUNTINGTYPES" :key="item.Id" :value="item.Id">
                            {{ item.name }}
                        </option>          
                    </select>
                </div>
                <div>
                    <el-input-number v-model="checkPointItemExtraNum" :min="1" :max="500" class="mx-4"/>
                </div>
            </div>
            <form @submit.prevent="handleSubmitCheckPointItem">
                <div class="d-flex justify-content-center" style="padding-top: 30px; padding-bottom: 30px; align-items: center;">                                        
                    <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">{{ getDlgCheckPointItemBtnText() }}</span>
                    <span v-else class="spinner"></span>
                    </button>                                        
                </div>
            </form>
        </el-dialog>
    </div> 
</div>  

</template>

<script setup>
import { useRouter } from 'vue-router'; 
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { getCurrentInstance,ref,computed,onMounted } from 'vue';
import { SERVICECATALOG,PROPERTYCONDS,BUILDINGTYPES,AREATYPES
    ,KITCHENTYPES,BATHTYPES,DRIVEWAYTYPES,COUNTINGTYPES } from '@/constants';
import apiClient from '@/apiClient';

const router = useRouter();
const store = useStore();
const { proxy } = getCurrentInstance();
const toast = useToast();

const catalogCount = ref(3); 
const errors = ref({}); 
const activeCatalog = ref(1);
const itemData = ref([]);
const serviceItemData = ref([]);
const dlgAddAdditionalItem = ref(false);
const dlgServiceItem = ref(false);
const isEditMode = ref(false);
const isEditServiceItemMode = ref(false);
const countType = ref([]);
const countingNum = ref(1);
const condType = ref(0);
const loading = ref(false);
const propertyAdditionalItemEditId = ref(0);

const serviceItemEditId = ref(0);
const serviceItemName = ref('');
const serviceItemDescription = ref('');
const serviceItemDisabled = ref(false);
const serviceItemNoCalc = ref(false);

const dlgCheckPointItem = ref(false);
const checkPointItemEditId = ref(0);
const checkPointItemName = ref('');
const checkPointItemBaseMin = ref(1);
const checkPointItemCountingType = ref(0);
const checkPointItemExtraNum = ref(1);
const checkPointItemEnableExtra = ref(false);
const checkPointItemServiceId = ref(0);

const curAdmin = store.getters.admin;


const  propertyCondOptions = [{
    value: 1,
    label:'BuildingType',
    children: [
        {
            value: 1,
            label: 'House'
        },
        {
            value: 2,
            label: 'Apartment/Condo'
        },
        {
            value: 3,
            label: 'Commercial'
        }
    ],    
},
{
    value: 2,
    label: 'Area',
    children: [
        {
            value: 1,
            label: '1000 to 2300 sq.ft, 4-5 bedrooms'
        },
        {
            value: 2,
            label: 'Fewer than 1000 sq.ft, fewer than 3 bedrooms'
        },
        {
            value: 3,
            label: '2300 to 4000 sq.ft, more than 5 bedrooms'
        },
        {
            value: 4,
            label: 'Over 4000 sq.ft'
        },
        {
            value: 5,
            label: 'Corner Lot'
        }
    ],
},
{
    value: 4,
    label: 'Kitchen',
    children: [
        {
            value: 1,
            label: '1 kitchen'
        },
        {
            value: 2,
            label: 'No kitchen'
        },
        {
            value: 3,
            label: '2 kitchens'
        },
        {
            value: 4,
            label: 'Over 2 kitchens'
        }
    ],
},
{
    value: 5,
    label: 'Bathroom',
    children: [
        {
            value: 1,
            label: 'Fewer than 4 bathrooms'
        },
        {
            value: 2,
            label: '5-6 bathrooms'
        },
        {
            value: 3,
            label: 'Over 6 bathrooms'
        },
        {
            value: 4,
            label: 'No half-bath'
        }
    ],
},
{
    value: 7,
    label: 'Driveway',
    children: [
        {
            value: 1,
            label: 'Standard double garage or smaller'
        },
        {
            value: 2,
            label: 'No garage'
        },
        {
            value: 3,
            label: 'Large then standard, fewer than 4 garages'
        },
        {
            value: 4,
            label: 'Large then 4 garages'
        }
    ],
}
];


function getWorkloadColor(countingType) {
    if(countingType === 3)
        return 'color: dodgerblue';
    else
        return 'color: firebrick';
}

function  getWorkloadPostfixText(countingType) {
    if(countingType === 2)
        return '%';
    else
        return 'mins';
}

function getServiceItemDisableColor(disabled) {
    if(disabled)
        return 'color: #CDD0D6';
    else
        return 'color: #333';
}

function getTxtCatalog(serviceCatalog) {  
  const catalog = computed(() => {
    const catalogItem = SERVICECATALOG.find(item => item.Id === serviceCatalog);  
    return catalogItem ? catalogItem.key : 'emptyKey'; 
  });
  if(catalog.value) {
    return catalog.value;
  } else {
    return 'emptyKey';
  }
}

function getPropertyCond(id) {
    const item = PROPERTYCONDS.find(it => it.Id === id);
    return item ? item.name : '';
}

function getPropertyAttr(condType, id) {
    if(condType === 1) {
        const building = BUILDINGTYPES.find(it => it.Id === id);
        return building ? building.key : 'emptyKey';
    } else if(condType === 2) {
        if(id === 5) {
            return 'selSvrDetail.propertyCornerLot';
        } else {
            const areaItem = AREATYPES.find(it => it.Id === id);
            return areaItem ? areaItem.key : 'emptyKey';
        }        
    } else if(condType === 4) {
        const kitchenItem = KITCHENTYPES.find(it => it.Id === id);
        return kitchenItem ? kitchenItem.key : 'emptyKey';
    } else if(condType === 5) {
        if(id === 4) {
            return 'selSvrDetail.propertyNoHalfBath';
        } else {
            const bathItem = BATHTYPES.find(it => it.Id === id);
            return bathItem ? bathItem.key : 'emptyKey';
        }
    } else if(condType === 7) {
        const driveItem = DRIVEWAYTYPES.find(it => it.Id === id);
        return driveItem ? driveItem.key : 'emptyKey';
    }
}

function getCountingType(id) {
    const item = COUNTINGTYPES.find(it => it.Id === id);
    return item ? item.name : '';
}


async function handleClick(tab) {
        
    const catalog = Number(tab.paneName);
    
    await loadServiceCatalogData(catalog);    
}

async function loadServiceCatalogData(catalog) {
    try {
        const response = await apiClient.post('/Staff/getServiceItemData',{
            serviceCatalog: catalog,
        });

        if(response.data) {
            itemData.value = response.data.additionalItems;
            serviceItemData.value = response.data.serviceItems;
        }

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    }
}


function getDlgCheckPointItemBtnText() {
    if(checkPointItemEditId.value>0) {
        return 'Save Item';
    } else {
        return 'Add Item';
    }
}


function getDlgCheckPointItemMainTitle() {
    if(checkPointItemEditId.value > 0) {
        return 'Edit Check Point Item';
    } else {
        return 'Add New Check Point Item';
    }
}


function getDlgServiceItemBtnText() {
    if(isEditServiceItemMode.value) {
        return 'Save Item';
    } else {
        return 'Add Item';
    }
}


function getDlgServiceItemMainTitle() {
    if(isEditServiceItemMode.value) {
        return 'Edit Service Item';
    } else {
        return 'Add New Service Item';
    }
}

function getDlgBtnText() {
    if(isEditMode.value) {
        return 'Save Item';
    } else {
        return 'Add Item';
    }
}

function getDlgMainTitle() {
    if(isEditMode.value ) {
        return 'Edit Additional Item';
    } else {
        return 'Add New Additional Item';
    }
}

function handleAddCheckPointItem(serviceId) {
    checkPointItemEditId.value = 0;
    dlgCheckPointItem.value = true;
    checkPointItemServiceId.value = serviceId;

    checkPointItemName.value = '';
    checkPointItemBaseMin.value = 1;
    checkPointItemCountingType.value = 0;
    checkPointItemExtraNum.value = 1;
    checkPointItemEnableExtra.value = false;
}


function handleAddServiceItem() {
    isEditServiceItemMode.value = false;
    serviceItemEditId.value = 0;
    dlgServiceItem.value = true;

    serviceItemName.value = '';
    serviceItemDescription.value = '';
    serviceItemDisabled.value = false;
    serviceItemNoCalc.value = false;
}

function handleAddAdditionalItem() {
    isEditMode.value = false;
    propertyAdditionalItemEditId.value = 0;
    dlgAddAdditionalItem.value = true;    

    condType.value = ref([]);
    countType.value = 0;
}

async function handleSubmitCheckPointItem() {
    // Check inputs
    if(checkPointItemServiceId.value <= 0 && checkPointItemEditId.value === 0) {
        toast.warning('ServiceId of the item is invalid while adding new item.');
        return;
    }
    if(!checkPointItemName.value || checkPointItemName.value == '') {
        toast.warning('Item name cannot be empty.');
        return;
    }
    if(checkPointItemBaseMin.value <= 0) {
        toast.warning('Base number is invalid.');
        return;
    }
    if(checkPointItemEnableExtra.value) {
        if(checkPointItemExtraNum.value > 0 && checkPointItemCountingType.value === 0) {
            toast.warning('Extra counting type must be set while Extra num is more than 0.');
            return;
        } 
        if(checkPointItemExtraNum.value <= 0 && checkPointItemCountingType.value > 0) {
            toast.warning('Extra num must be set while Extra counting type has been set.');
            return;
        }
    }

    loading.value = true;

    try {
        if(checkPointItemEditId.value > 0) {
            const response = await apiClient.post('/Staff/saveCheckPointItem', {
                serviceId: checkPointItemServiceId.value,
                itemName: checkPointItemName.value,
                timeConsuming: checkPointItemBaseMin.value,
                extraCountingType: checkPointItemCountingType.value,
                extraConsuming: checkPointItemExtraNum.value,
                itemId: checkPointItemEditId.value,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }            

        } else {
            const response = await apiClient.post('/Staff/addCheckPointItem', {
                serviceId: checkPointItemServiceId.value,
                itemName: checkPointItemName.value,
                timeConsuming: checkPointItemBaseMin.value,
                extraCountingType: checkPointItemCountingType.value,
                extraConsuming: checkPointItemExtraNum.value,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }            
        }

        checkPointItemServiceId.value = 0;
        loading.value = false;        
        dlgCheckPointItem.value = false;

        await loadServiceCatalogData(activeCatalog.value);

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    } finally {
        loading.value = false;
    }

}


async function handleSubmitServiceItem() {
    // Check inputs
    if(!serviceItemName.value || serviceItemName.value === '') {
        toast.warning('Item name cannot be empty.');
        return;
    }    

    loading.value = true;

    try {
        if(isEditServiceItemMode.value) {
            const response = await apiClient.post('/Staff/saveServiceItem', {
                serviceCatalog: activeCatalog.value, 
                name: serviceItemName.value,
                description: serviceItemDescription.value,
                disabled: serviceItemDisabled.value,
                noCalc: serviceItemNoCalc.value,
                serviceId: serviceItemEditId.value,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }
            isEditServiceItemMode.value = false;

        } else {
            const response = await apiClient.post('/Staff/addServiceItem',{
                serviceCatalog: activeCatalog.value, 
                name: serviceItemName.value,
                description: serviceItemDescription.value,
                disabled: serviceItemDisabled.value,
                noCalc: serviceItemNoCalc.value,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }            
        } 

        loading.value = false;
        dlgServiceItem.value = false;

        await loadServiceCatalogData(activeCatalog.value);

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    } finally {
        loading.value = false;
    }
}


async function handleSubmitAddAdditionalItem() {
    // Check inputs
    if(condType.value[0] === 0 || condType.value[1] === 0 || condType.value === 0) {
        toast.warning('Property condition/attribute must be selected.');
        return;
    }
    if(countType.value === 0) {
        toast.warning('Counting type must be selected.');
        return;
    }
    if(countingNum.value <= 0) {
        toast.warning('Counting number invalid.');
        return;
    }

    loading.value = true;

    try {

        if(isEditMode.value) {
            const response = await apiClient.post('/Staff/savePropertyAdditionalItem',{
                itemId: propertyAdditionalItemEditId.value,
                serviceCatalog: activeCatalog.value,
                condType: condType.value[0],
                propertyAttr: condType.value[1],
                countingType: countType.value,
                consuming: countingNum.value,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }
            isEditMode.value = false;

        } else {        
            const response = await apiClient.post('/Staff/addPropertyAdditionalItem', {
                serviceCatalog: activeCatalog.value,
                condType: condType.value[0],
                propertyAttr: condType.value[1],
                countingType: countType.value,
                consuming: countingNum.value,
            });

            if(response.data) {
                toast.info(response.data.msg);            
            }
        }

        loading.value = false;
        dlgAddAdditionalItem.value = false;

        await loadServiceCatalogData(activeCatalog.value);

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    } finally {
        loading.value = false;
    }    
}


async function handleRemoveCheckPointItem(id, serviceId) {
    if(confirm(`Are you sure you want to remove the check point item, Id=${id}?`)) {
        try {
            const response = await apiClient.post('/Staff/removeCheckPointItem', {
                itemId: id,
                serviceId: serviceId,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }

            await loadServiceCatalogData(activeCatalog.value);

        } catch(error) {
            if(error.response && error.response.data && error.response.status == 400) {                    
                errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
            } else {                
            if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logoutAdmin');
                router.push('/');
            } else {
                toast.error(error.code);                    
            }                                                            
            }
        }
    } 
}


async function handleRemoveAdditionalItem(id) {
    if(confirm(`Are you sure you want to remove the additional item, Id=${id}?`)) {
        try {
            const response = await apiClient.post('/Staff/removePropertyAdditionalItem',{
                serviceCatalog: activeCatalog.value,
                Id: id,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }

            await loadServiceCatalogData(activeCatalog.value);

        } catch(error) {
            if(error.response && error.response.data && error.response.status == 400) {                    
                errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
            } else {                
            if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logoutAdmin');
                router.push('/');
            } else {
                toast.error(error.code);                    
            }                                                            
            }
        }
    }
}


async function handleRemoveServiceItem(id) {
    if(confirm(`Are you sure you want to remove the service item, Id=${id}?`)) {
        try {
            const response = await apiClient.post('/Staff/removeServiceItem', {
                serviceCatalog: activeCatalog.value,
                serviceId: id,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }

            await loadServiceCatalogData(activeCatalog.value);

        } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
            if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logoutAdmin');
                router.push('/');
            } else {
                toast.error(error.code);                    
            }                                                            
        }
        }
    }
}


function handleEditCheckPointItem(id, serviceId) {
    checkPointItemEditId.value = id;
    dlgCheckPointItem.value = true;
    checkPointItemServiceId.value = serviceId;

    console.log('id,serviceId:', id, serviceId);
    const item = serviceItemData.value.find(it => it.serviceId === serviceId);
    if(item) {
        console.log('item:', item);
        var subItem = item.checkPointItems.find(it => it.id === id);
        checkPointItemName.value = subItem?.itemName;
        checkPointItemBaseMin.value = subItem?.timeConsuming;
        checkPointItemCountingType.value = subItem?.extraCountingType;
        if(checkPointItemCountingType.value > 0) {
            checkPointItemEnableExtra.value = true;
        } else {
            checkPointItemEnableExtra.value = false;
        }
        checkPointItemExtraNum.value = subItem?.extraConsuming;
    }
}

function handleEditAdditionalItem(id) {
    isEditMode.value = true;
    propertyAdditionalItemEditId.value = id;
    dlgAddAdditionalItem.value = true;
    
    const item = itemData.value.find(it => it.id === id);    
    condType.value = [item?.condType, item?.propertyAttr];
    countType.value = item?.countingType;
    countingNum.value = item?.consuming;       
}

function handleEditServiceItem(id) {
    isEditServiceItemMode.value = true;
    serviceItemEditId.value = id;
    dlgServiceItem.value = true;

    const item = serviceItemData.value.find(it => it.serviceId === id);
    serviceItemName.value = item?.name;
    serviceItemDescription.value = item?.description;
    serviceItemDisabled.value = item?.disabled;
    serviceItemNoCalc.value = item?.noCalc;
}


onMounted(() =>{    

    if(!store.getters.isAdminLogin) {
        router.push('/admin');
        return;
    } 

    loadServiceCatalogData(activeCatalog.value);

  });

</script>

<style scoped>

.container {
  
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;    
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 30px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;  
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

h4 {
  font-size: 20px;
  font-weight: bold;
}

.links {
  text-align: left;
  width: auto;
}

.links a {  
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 96%;
}

.summarySub {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 360px;  
}

input[type="text"],
select {
  padding: 10px;
  padding-right: 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;  
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 54%;
  
}

button:hover {
  background-color: #0056b3;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

</style>