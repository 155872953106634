<template>
    <div class="row">
   <div class="container">
    <h2>{{ $t('createAccTitle') }}</h2>
    <form @submit.prevent="handleSubmit">
      <!-- Name Fields -->
      <div class="row w-100">
        <div class="d-flex justify-content-between data-row">
          <div class="col-6" :class="{'has-error': errors.firstName}">         
            <input
              type="text"
              id="firstName"
              v-model="form.firstName"
              :placeholder="$t('createAccFirstName')"
              @focus="clearError('firstName')"
              required
            />
          </div>
          <div class="col col-6">          
            <input
              type="text"
              id="lastName"
              v-model="form.lastName"
              :placeholder="$t('createAccLastName')"            
            />
          </div>
        </div>       

        <p v-if="errors.firstName" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.firstName }} 
        </p>
      </div>

      <hr class="divider-line" />

      <!-- Email and Password -->
      <div class="row w-100">
        <div class="row data-row" :class="{'has-error': errors.email}">        
          <input
            type="email"
            id="email"
            v-model="form.email"
            :placeholder="$t('createAccEmail')"
            @focus="clearError('email')"
            required
          />
        </div>

        <p v-if="errors.email" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.email }} 
        </p>
      </div>

       <!-- Phone Number -->
       <div class="row w-100">
        <div class="row data-row" :class="{'has-error': errors.phoneNumber}">        
          <input
            type="tel"
            id="phoneNumber"
            v-model="form.phoneNumber"
            :placeholder="$t('createAccPhoneNo')"
            @focus="clearError('phoneNumber')"          
          />
        </div>      

        <!-- Error Message -->
        <p v-if="errors.phoneNumber" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.phoneNumber }} 
        </p>
      </div>
      
      <hr class="divider-line" />

      <div class="row w-100">
        <div class="row data-row" :class="{'has-error': errors.password}">        
          <input
            type="password"
            id="password"
            v-model="form.password"
            :placeholder="$t('createAccPassword')"
            @focus="clearError('password')"
            required
          />
        </div>
      
        <div class="row data-row" :class="{'has-error': errors.confirmPassword}">        
          <input
            type="password"
            id="confirmPassword"
            v-model="form.confirmPassword"
            :placeholder="$t('createAccConfirmPassword')"
            @focus="clearError('confirmPassword')"
            required
          />        
        </div>     

        <!-- Error Message -->
        <p v-if="errors.password || errors.confirmPassword" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.password || errors.confirmPassword }} 
        </p>      
      </div>

     

      <hr class="divider-line" />

      <!-- Date of Birth -->
      <div class="row w-100">
        <div class="datepicker-container data-row" >            
            <div style=" width:100%; max-width: 560px;" :class="{'has-error': errors.dob}">
            <Datepicker           
              id="dob"
              v-model="form.dob" 
              :format="customFormat" 
              :monday-first="true"  
              @focus="clearError('dob')" 
              :placeholder="$t('createAccDob')"
              style="padding: 10px; width: 100%; max-width: 560px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box; text-align: center;"               
            />        
          </div> 
        </div>

        <p v-if="errors.dob" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.dob }} 
        </p> 
      </div>
     

      <!-- Checkbox for Email Notifications -->
      <div class="row w-100">
        <div class="row checkbox-row">              
          <label for="emailNotifications" style="width: auto;">
            <input
              type="checkbox"
              id="emailNotifications"
              v-model="form.emailNotifications"
            />
            <span>{{ $t('createAccEmailNotification') }}</span>
          </label>                  
        </div>
      </div>

      <hr class="divider-line" />

      <!-- Validation code -->
      <div class="row w-100">
        <div class="row">
          <div class="col-validation">
              <img :src="captchaImageUrl" alt="Captcha" @click="refreshCaptcha" id="validationCodeImg"/>
          </div>
          <div class="col col-validation-text" :class="{'has-error': errors.validationCode}">         
            <input
              type="text"
              id="validationCode"
              v-model="form.validationCode"
              :placeholder="$t('createAccValidation')"
              @focus="clearError('validationCode')"
              required
            />
          </div>        
        </div>

        <p v-if="errors.validationCode" class="error-message">
          <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.validationCode }} 
        </p>   

        <div class="row links">        
          <a href="#" @click.prevent="refreshCaptcha" style="text-align: center;">{{ $t('createAccValidationRenew') }}</a>            
        </div>
      </div>

      <div v-if="fatalShowError" class="error-box">
          {{ fatalShowError }}          
      </div>

      <hr class="divider-line" />

      <!-- Submit Button -->
      <div class="row">
        <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">{{ $t('createAccSubmit') }}</span>
            <span v-else class="spinner"></span></button>
      </div>
    </form>      
  </div>
</div>
</template>
  
  <script>
  import { ref } from 'vue';
  import apiClient from '@/apiClient.js';
  import { useRouter } from 'vue-router';  
  import { useToast } from 'vue-toastification';
  import { getCurrentInstance } from 'vue';

  
  export default {
    name: "CreateAccount",
    setup() {      
      const router = useRouter();      
    
      const form = ref({
        firstName: '',
        lastName: '',        
        dob: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        emailNotifications: true,
        validationCode: '',
        });
      const captchaImageUrl = ref('');
      const errors = ref({});
      const loading = ref(false);      
      const passwordShowError = ref('');
      const infoShowError = ref('');
      const validationShowError = ref('');
      const fatalShowError = ref('');
      const customFormat = 'YYYY-MM-DD';
      const toast = useToast();
      const { proxy } = getCurrentInstance();

        const handleSubmit = async () => {
        
            loading.value = true;

            console.log('Form Submitted', form.value);            

            if(form.value.confirmPassword != form.value.password) {
                errors.value['confirmPassword'] = 'The passwords you entered do not match.';
                errors.value['password'] = 'The passwords you entered do not match.';                
                loading.value = false;
                return;
            }
            else if(form.value.password.length<6) {
                errors.value['confirmPassword'] = 'The password your entered does not meet the minimum length requirement.';
                errors.value['password'] = 'The password your entered does not meet the minimum length requirement.';                
                loading.value = false;
                return;
            }

            try {

                const response = await apiClient.post('/Account/createAccount',{
                    firstName: form.value.firstName,
                    lastName: form.value.lastName,            
                    dob: form.value.dob,
                    email: form.value.email,
                    phoneNumber: form.value.phoneNumber,
                    password: form.value.password,
                    emailNotifications: form.value.emailNotifications,
                    validationCode: form.value.validationCode,
                });

                const datas = response.data;
                if(datas) {
                    console.log('Data:', datas);
                }                    

                errors.value = {};

                toast.success(response.data.msg);

                setTimeout(() => {
                  router.push('/login');
                }, 2000);

            } catch(error) {
                console.error('Create account failed:', error);

                if(error.response && error.response.data && error.response.status==400) {                    
                    errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
                }
                else {                    
                    toast.error(error.code);
                }

            } finally {
                loading.value = false;
            }
        };

        const fetchCaptcha = async () => {

            try {
                const response = await apiClient.get('/Account/generateValidation', { responseType: 'blob'});
                const blob = response.data;
                captchaImageUrl.value = URL.createObjectURL(blob); 
            } catch(error) {                
                if(error.response && error.response.data && error.response.status==400) {
                    console.error("Error fetching captcha:", error.response.data);
                    errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);
                }
                else {                    
                    toast.error(error.code);
                }
            }
        };

        const refreshCaptcha = () => {
            fetchCaptcha();
        };

        const clearError = (field) => {
            if(errors.value[field]) {

                if(field == 'password' || field == 'confirmPassword') {
                    delete errors.value['password'];
                    delete errors.value['confirmPassword'];                    
                }
                else {
                    delete errors.value[field];
                }                               
            }            
        };

      return {
        form,
        handleSubmit,        
        captchaImageUrl,
        fetchCaptcha,
        refreshCaptcha,
        errors,
        clearError,
        loading,
        passwordShowError,
        infoShowError,
        validationShowError,
        fatalShowError,
        customFormat,
      };
    },
    mounted() {
      this.fetchCaptcha();
    }
  };
  </script>
  
  <style scoped>

.data-row {        
  max-width: 560px;
}

  .container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

h2 {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;
}

.row {
  margin-bottom: 15px;
  display: flex;  
  justify-content: center;      
}


.name-row {
  justify-content: center; 
}

.col {
  display: flex;
  flex-direction: column;      
}

.col-6 {
  width: calc(50% - 5px);  
}

.col-6:first-child {
  margin-right: 10px;
}

.col-7 {
  width: 100%;  
  justify-content: center;
  display: flex;
}

.col-validation {
  width: 170px;     
}

.col-validation-text {
  width: calc(50% - 5px);
  max-width: 240px;
}

.col-validation-label {  
  width:100%;
  display: flex;
  justify-content: center;
}


label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="date"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;  
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 54%;
  
}

button:hover {
  background-color: #0056b3;
}

.checkbox-row {
  max-width: 560px;  
  padding: 0px;  
}

.checkbox-row input[type="checkbox"] {
  margin-right: 6px;
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.error-message {
  color: #de071c;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-icon {
  margin-right: 5px;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.row.has-error Datepicker {
  border-color: red !important;
  background-color: #fce8e6 !important;
}

.row.has-error input {
  border-color: red;
  background-color: #fce8e6 !important;
}

.col.has-error input {
  border-color: red;
  background-color: #fce8e6 !important;
}

.error-box {
  margin-bottom: 20px; 
  padding: 30px;
  background-color: #fae9a3;
  border: 1px solid #dbc156;
  border-radius: 5px;
  text-align: center;
  color: #333;
  position: relative;
  font-size: 14px;  
  width: auto;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.error-box::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent #ffe9c8 transparent;
}

.datepicker-container {    
  margin-bottom: 20px;
  position: relative;
  display: flex;  
  justify-content: center;    
  width: 100%;
}

.datepicker-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.vue3-datepicker__calendar {
  position: absolute;
  top: 50px; 
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.vue3-datepicker__calendar-header {
  background-color: #f0f0f0;
}

.vue3-datepicker__calendar-day:hover {
  background-color: #007bff;
  color: #fff;
}

.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

</style>
  