<template>    
  <div class="row">
    <h2 style="text-align: center; padding: 20px;">{{ $t('inConstruction') }}</h2>   
  </div>  

</template>
  
  <script setup>
  


  </script>
  
  <style scoped>

  </style>
  