<template>
    <div class="container my-3">
      <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3> 
      <hr class="divider-line0" />

      <h2>{{ $t('addressDetailTitle') }}</h2>        

    <div class="row">

        <!-- Existing Addresses -->
        <div v-for="address in addresses" :key="address.addressId" class="col-md-4 col-sm-6  mb-4 address-card">                                   
            <div :class="address.isDefault ? 'address-card-header' : 'address-card-nonheader'"  >
              <small v-if="address.isDefault" class="text-muted">{{ $t('addressDetailDefaultFlag') }}</small>
            </div>
            <div class="card-body">
              <strong>{{ address.firstName }} {{ address.lastName }} </strong>
              <p class="mb-1">{{ address.unit ? address.unit:'' }} {{ address.streetNo ? address.streetNo:'' }} {{ address.streetName }}</p>
              <p class="mb-1">{{ address.city }}, {{ address.province }} {{ address.postal }}</p>
              <p class="mb-1">{{ address.country }}</p>           
                  
            </div>   
            <br v-if="!address.isDefault">
            <div class="card-footer d-flex justify-content-between">              
              <div class="d-flex justify-content-center">
                  <a href="#" @click.prevent="editAddress(address.addressId)">{{ $t('myAccSettingsEdit') }}</a> 
                  <div style="padding-left: 5px;" v-if="!address.isDefault">  
                    | <a href="#" @click.prevent="removeAddress(address.addressId)">{{ $t('addressDetailLinkRemove') }}</a>
                  </div>
              </div>        
              <div v-if="!address.isDefault">                
                <a  href="#" @click.prevent="setAsDefault(address.addressId)" class="ml-auto">{{ $t('addressDetailLinkSetDefault') }}</a>                      
              </div>                   
            </div>          
        </div>      
             
        <div class="col-md-4 col-sm-6 mb-4">
            <div class="card h-100 add-new-address text-center" @click="addAddress">
            <div class="card-body d-flex justify-content-center align-items-center flex-column">
                <span class="plus-icon">+</span>
                <p><strong>{{ $t('addressDetailLinkAdd') }}</strong></p>
            </div>
            </div>
        </div>  

    </div>
   
    </div>
</template>
  
<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; 
import apiClient from '@/apiClient';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { getCurrentInstance } from 'vue';


export default {
name: "UserAddressDetail",
setup() {
    const store = useStore();
    const router = useRouter();
    const errors = ref({});
    const { proxy } = getCurrentInstance();
    const toast = useToast();
    const addresses = ref({});

    const curUser = store.getters.user;

    if(!store.getters.islogin) {
        router.push('/');
    }

    const getAccountAddresses = async () => {
      try {
        const response = await apiClient.post('/Account/getAccountAddresses', { userId: curUser.userId});
        addresses.value = response.data.addresses;        

      } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
                errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
            } else {                
              if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logout');
                router.push('/');
              } else {
                toast.error(error.code);                    
              }                                                            
        }
      }
    };

    const  addAddress = () => {
      router.push('/addUserAddress');
    };


    const setAsDefault = async (Id) => {
      if(confirm("Are you sure you want to set this as your default address?")) {
        try {
          const response = await apiClient.post('/Account/setAccountDefaultAddress', {
            userId: curUser.userId,
            addressId: Id
          });

          if(response && response.data) {
            toast.success(response.data.msg);
          }          

          // Refresh
          getAccountAddresses();

        } catch(error) {
          if(error.response && error.response.data && error.response.status == 400) {                    
                errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
            } else {                
              if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logout');
                router.push('/');
              } else {
                toast.error(error.code);                    
              }                                                            
          }
        }

      }      
    };

    const removeAddress = async (Id) => {
      if(confirm("Are you sure you want to remove this address?")) {
        try {
          const response = await apiClient.post('/Account/removeAccountAddress', {
            userId: curUser.userId,
            addressId: Id
          });

          if(response && response.data) {
            toast.success(response.data.msg);
          }          

          // Refresh
          getAccountAddresses();

        } catch(error) {
            if(error.response && error.response.data && error.response.status == 400) {                    
                errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
            } else {                
              if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logout');
                router.push('/');
              } else {
                toast.error(error.code);                    
              }                                                            
          }
        }
      }
    };

    const editAddress = (Id)=> {
      router.push({name:'editUserAddress', params:{ addressId: Id }});
    };

    return {
        curUser,
        getAccountAddresses,
        errors,
        addresses,
        addAddress,
        setAsDefault,
        removeAddress,
        editAddress,
    };
},
mounted() {
  this.getAccountAddresses();
}
};
</script>
  
<style scoped>

.address-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 340px;  
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
}

.address-card-header {
  padding: 8px 16px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;      
}

.address-card-nonheader {
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;      
}

.card-body {
  padding: 12px 16px;
}

.card-body strong {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
}

.card-body a {
  color: #0071e3;
  text-decoration: none;
}

.card-body a:hover {
  text-decoration: underline;
}

.card-footer {
  padding: 12px 16px;
  background-color: #fff;  
}


.container {    
  padding: 20px;
  max-width: 1100px;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;    
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
  padding-top: 20px;
  padding-bottom: 20px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;         
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

.add-new-address {
border: 2px dashed #bbb;
cursor: pointer;
padding: 8px 16px;
max-width: 340px;  
border-radius: 8px;
background-color: #fff;
margin: 10px;
overflow: hidden; 
}

.plus-icon {
font-size: 50px;
margin-bottom: 10px;
}

.default-label {
font-size: 12px;
color: #999;
}


.card-body p {
margin: 0;
color: #555;
}

.address-card .card-footer a {
color: #007aff;
  text-decoration: none;
}

.address-card .card-footer a:hover {
text-decoration: underline;
}

.modal {
background: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
max-width: 500px;
margin: 2rem auto;
}

.ml-auto {
  margin-left: auto; 
}

</style>
  