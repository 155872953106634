<template>    
   <div class="container">
    <div style="align-items: center;" class="d-flex justify-content-between links">
        <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3>        
        <a href="#" @click.prevent="backToOrderList">{{ $t(showLinkName()) }} ></a> 
    </div>

    <hr class="divider-line0"/>

    <div class="row">        
      <h2 style="padding-bottom: 0px;">{{ $t(txtMainTitle) }}</h2>      
    </div>

    <div class="row">
      <ul v-infinite-scroll="load" class="infinite-list" style="overflow: auto;"  >
        <li v-for="i in count" :key="i" class="infinite-list-item" >
          <div class="summary" v-if="orderData[i-1]">
            <div style="background-color: #F0F2F2;  padding-top: 6px; padding-bottom: 0px; padding-left: 16px; padding-right: 16px; font-size: 12px; color:#333;" class="d-flex justify-content-between">
              <div>
                {{ $t('orderList.topTitleOrderDate') }}<br>
                {{ MONTHS[new Date(orderData[i-1]?.createTime).getMonth()] }}. {{ new Date(orderData[i-1]?.createTime).getDate() }},
                {{ new Date(orderData[i-1]?.createTime).getFullYear() }}
              </div>
              <div style="color:firebrick">
                {{ $t('orderList.topTitleTotal') }}<br>
                {{ orderData[i-1]?.totalPoints}}
              </div>              
              <div class="links" style="width:auto;" :id="`#${orderData[i-1]?.orderNo}`">
                {{ $t('orderList.topTitleOrderNo') }} # {{ orderData[i-1]?.orderNo }}<br>
                <a href="#" @click.prevent="viewOrderDetails(orderData[i-1]?.orderNo)" style="font-size: 14px;">{{ $t('orderList.topTitleLinkViewDetails') }} ></a>                
              </div>              
            </div>
            <div><hr class="divider-line1"/></div>
            <div style="padding: 6px; padding-left: 16px; padding-right: 16px; font-size: 16px;" class="d-flex justify-content-start">
              <div style="width:110px; padding: 10px; padding-left: 0px;">                
                <div :title="$t(getTxtCatalog(i-1))" style="padding-top: 0px; padding-bottom: 4px;"><img :src="getImagePath(orderData[i-1]?.serviceCatalog)" class="card-img-top img-fluid" style="width: 90px;" :alt="getTxtCatalog(i-1)" /></div>
                <div :style="{color: getStatusColor(orderData[i-1]?.status)}" style="text-align: center; font-weight: 600; font-size: 13px;">{{ $t(getTxtOrderStatus(i-1)) }}</div>
              </div>
              <div>
                <div style="color: chocolate; font-weight: 600; padding-bottom: 6px;">
                  {{ orderData[i-1]?.name }}  
                </div>
                <div style="font-size: 14px;">
                  {{ orderData[i-1]?.unit ? orderData[i-1]?.unit:'' }} {{ orderData[i-1]?.streetNo ? orderData[i-1]?.streetNo:'' }} 
                  {{ orderData[i-1]?.streetName }}<br>{{ orderData[i-1]?.city }}, {{ getTxtProvinceName(i-1) }} {{ orderData[i-1]?.postal }}
                </div>                       
                <div style="padding-top: 6px; width:100%;"><hr class="divider-line1"/></div>
                <div style="font-size: 14px; color: #0aa06e; font-weight: 600;" >{{ $t(getDayOfWeekOnSiteTime(i-1)) }}, 
                  {{ MONTHS[new Date(orderData[i-1]?.bookTime).getMonth()] }}. {{ new Date(orderData[i-1]?.bookTime).getDate() }}, 
                  {{ new Date(orderData[i-1]?.bookTime).getFullYear() }} <span v-if="orderData[i-1]?.serviceCatalog === 1" style="font-weight: 600; color:#333;">&nbsp;MDT</span>
                  <span v-if="orderData[i-1]?.serviceCatalog === 1"><br>by {{ new Date(orderData[i-1]?.bookTime).getHours() }}:{{ new Date(orderData[i-1]?.bookTime).getMinutes() === 0 ? '00' : new Date(orderData[i-1]?.bookTime).getMinutes() }} {{ getTxtSlot(new Date(orderData[i-1]?.bookTime).getHours()) }}</span>
                  <span v-if="orderData[i-1]?.serviceCatalog != 1" style="font-weight: 600; color:#333;">&nbsp;MDT</span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <p v-if="noMore" style="text-align: center; color: #333; font-size: 12px; padding-top: 8px;">{{ $t('orderList.reachEndTitle') }}</p>
    </div>

    
   </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; 
import { useToast } from 'vue-toastification';
import { getCurrentInstance,ref,onMounted, computed, nextTick } from 'vue';
import apiClient from '@/apiClient';
import { MONTHS,SERVICECATALOG,ORDERSTATUS,PROVINCES,WEEKDAYS } from '@/constants';

const { proxy } = getCurrentInstance();
const toast = useToast();
const errors = ref({}); 
const store = useStore();
const router = useRouter();

const orderData = ref([]);
const slotAMStartHour = ref(9);
const slotAMEndHour = ref(11);
const slotPMStartHour = ref(12);
const slotPMEndHour = ref(14);

const curUser = store.getters.user;
  if(!store.getters.islogin) {
      router.push('/');
  }

const listCatalog = Number(router.currentRoute.value.params.catalog);

const txtMainTitle = listCatalog === 0 ? 'orderList.mainTitleOngoing' : 'orderList.mainTitleHistory';

const noMore = computed(() => count.value >= orderData.value.length)

const count = ref(30);

function getDayOfWeekOnSiteTime(i) {
  const time = computed(() => {
    if(new Date(orderData.value[i]?.bookTime).getDate())
      return WEEKDAYS[new Date(orderData.value[i]?.bookTime).getDay()];
    else
      return WEEKDAYS[0];
  });
  if(time.value)
    return time.value;
  else
    return 'emptyKey'
}

function getTxtProvinceName(i) {
    return computed(() => {
    const item = PROVINCES.find(item => item.Id === orderData.value[i]?.provinceId);
    return item ? item.name : '';
  });  
}

function getTxtCatalog(i) {  
  const catalog = computed(() => {
    const catalogItem = SERVICECATALOG.find(item => item.Id === Number(orderData.value[i]?.serviceCatalog));  
    return catalogItem ? catalogItem.key : 'emptyKey'; 
  });
  if(catalog.value) {
    return catalog.value;
  } else {
    return 'emptyKey';
  }
}

function getTxtOrderStatus(i) {  
    if(orderData.value[i]?.status && orderData.value[i]?.status<6)
      return ORDERSTATUS[orderData.value[i]?.status];
    else 
      return ORDERSTATUS[0];
}

function getImagePath(catalog) {
  if(catalog === 1) {
    return require('@/assets/service_housecleaning.jpg');
  } else if(catalog === 2) {
    return require('@/assets/service_lawncare.jpg');
  } else if(catalog === 3) {
    return require('@/assets/service_snowremoval.jpg');
  }  
}

function getStatusColor(status) {

if(status>5) {
  return 'chocolate';
} else {
switch(status) {
  case 0:
    return 'chocolate';
  case 1:
    return '#0aa06e';
  case 2:
    return '#00CED1';
  case 3:
    return '#007BFF';
  case 4:
    return '#8B0000';
  case 5:
    return '#DC3545';      
}
}
}

function getTxtSlot(hour) {
  if(hour >= slotAMStartHour.value && hour <= slotAMEndHour.value)
    return 'AM';
  else if(hour >= slotPMStartHour.value && hour <= slotPMEndHour.value)
    return 'PM';
  else {
    if(hour < slotPMStartHour.value)
      return 'AM';
    else
    return 'PM';
  }    
}

function backToOrderList() {
    if(listCatalog === 1) {      
      router.push({name:'userOrderList', params: {
        catalog: 0
      }}).then(() => {
        router.go(0);
      });
    } else {
      router.push({name:'userOrderList', params: {
        catalog: 1
      }}).then(() => {
        router.go(0);
      });
    }
   }

   function showLinkName() {
    if(listCatalog === 1)
      return 'orderDetails.linkOrderList1';
    else
      return 'orderDetails.linkOrderList2';
   }

function load() {
  
  setTimeout(() => {    
    
    if(count.value + 30 <= orderData.value.length)
      count.value += 30;
    else 
      count.value += orderData.value.length - count.value;

  }, 300);
}

function viewOrderDetails(orderNo) {
  router.push({name:'userOrderDetail', params:{ orderNo: orderNo }});
}

async function loadUserOrderList() {
  try {
    const response = await apiClient.post('/Service/getUserOrderList',{
      userId: curUser.userId,
      catalog: listCatalog,
    });

    if(response.data) {
      orderData.value = response.data.orderData;
      slotAMStartHour.value = response.data.slotAMStartHour;
      slotAMEndHour.value = response.data.slotAMEndHour;
      slotPMStartHour.value = response.data.slotPMStartHour;
      slotPMEndHour.value = response.data.slotPMEndHour;

      const posOrderNo = router.currentRoute.value.query.posOrderNo;      
      if(posOrderNo) {

        await nextTick();

        const orderElement = document.querySelector(`[id="#${posOrderNo}"]`);
        console.log('posNoElemNon:', orderElement);
        if(orderElement) {
          orderElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }      
    }    

  } catch(error) {
    if(error.response && error.response.data && error.response.status == 400) {                    
        errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
    } else {                
      if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logout');
        router.push('/');
      } else {
        toast.error(error.code);                    
      }                                                            
    }
  }
}


onMounted(() =>{    

  loadUserOrderList();

});

</script>

<style scoped>

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
  padding-top: 10px;
  padding-bottom: 10px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;         
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-top: 0px;
  width:100%;
}

.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.infinite-list {
  height: 700px;
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 230px;  
  margin: 0px;  
}
.infinite-list .infinite-list-item + .list-item {
  margin-top: 8px;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 96%;
}

.color-block {
  width: 90px;
  height: 6px;
  background-color: #3498db; /* 蓝色块 */
  cursor: pointer;  
  display: inline-block;
  position: relative;
}

</style>
    