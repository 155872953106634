<template> 

<div class="row">
  <div class="container">
    <div class="row" style="padding-top: 0px;" >
      <div class="d-flex justify-content-between">
        <div>
          <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3> 
        </div>
        <div class="menu-container" ref="menuContainer">
          <button @click.prevent="toggleMenu" class="menu-button">{{ $t('myAccQuickBook') }}</button>
          <div v-show="showMenu" class="dropdown-menu">
            <ul>
              <li @click.prevent="checkToBook(1)">{{ $t('houseCleaningTitle') }}</li>
              <li @click.prevent="checkToBook(3)">{{ $t('snowRemovalTitle') }}</li>
              <li @click.prevent="checkToBook(2)">{{ $t('lawnCareTitle') }}</li>
            </ul>
        </div>
  </div>
      </div>    

      <hr class="divider-line0" />
      
      <h2>{{ $t('myAccTitle') }}</h2>        
     
    </div>    
    
    <div class="row justify-content-center">

      <!-- Points balance -->      
      <div class="col-md-4 col-sm-6 mb-3">
        <div class="card p-3 shadow-sm">        
          <div class="card-body">
            <h5 class="card-title">{{ $t('myAccPointBalance') }}: {{ resultData.points }} </h5>
            <p v-if="resultData.rewardPoints>0" style="font-size:14px; font-weight: 100;">{{ $t('myAccRewardPoints') }}: {{ resultData.rewardPoints }}</p>
            <p class="card-text">
              You have recently cost 100 points.
            </p>
            <div class="links d-flex" style="padding-top: 16px;">
              <a href="#">{{ $t('myAccPointsCharge') }} ></a>              
            </div>
            <div class="links d-flex">
              <a  href="#">{{ $t('myAccBalanceHistory') }} ></a>
            </div>
          </div>
        </div>
      </div>

      <!-- Level and Exp -->
      <div class="col-md-4 col-sm-6 mb-3">
        <div class="card p-3 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">{{ $t('myAccRankTitle') }}: {{ resultData.rank }}</h5>           
            <h5 class="card-title">{{ $t('myAccTitleName') }}:  <img :src="'/titles/' + resultData.titleIcon + '.png'" :alt="resultData.title" width="32">              
               <a href="#" class="custom-link" @click.prevent="navigateToPage('/rankDetail')" >{{ resultData.title }} ></a>              
               </h5> 
            <p class="card-text">{{ $t('myAccNextExp') }}: {{ resultData.exp }}/{{ resultData.nextExp }}<br>
            <el-progress                            
              :stroke-width="10"              
              :percentage="((resultData.exp/resultData.nextExp)*100).toFixed(1)"      
              style="width: calc(auto - 30px);"
            >
            <template #default>
              <span style="color: green; font-weight: bold; font-size: 15px;">{{ ((resultData.exp/resultData.nextExp)*100).toFixed(0) }}%</span>
            </template>
            </el-progress>
            </p>                                      
            <div class="links d-flex" style="padding-top: 16px;">
              <a href="#">{{ $t('myAccExpHistory') }} ></a>              
            </div>
          </div>
        </div>
      </div>

       <!-- Order -->
       <div class="col-md-4 col-sm-6 mb-3">
        <div class="card p-3 shadow-sm">
          <div class="card-body">
            <h5 class="card-title">{{ $t('myAccOrderTitle') }}</h5>
            <p class="card-text">You have 1 order within the past 18 months.</p>
            <div class="links d-flex">
              <a  href="#" @click.prevent="goOrderList(0)">{{ $t('orderDetails.linkOrderList1') }} ></a>
            </div>
            <div class="links d-flex">
              <a href="#" @click.prevent="goOrderList(1)">{{ $t('orderDetails.linkOrderList2') }} ></a>
            </div>            
          </div>
        </div>
      </div>            
    </div>      

    <hr class="divider-line" />

    <div class="row justify-content-center">
      <h2>{{ $t('myAccSettings') }}</h2>      
    </div>

    <!-- Address Section -->
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <h4>{{ $t('myAccSettingsAddress') }}</h4>
      </div>
      <div class="col-12 col-md-9">
        <div class="row">
          <div class="col-md-6">
            <strong>{{ $t('myAccSettingsFrequentTitle') }}</strong>
            <div v-if="resultFrequentAddress.firstName">
              <p>{{ resultFrequentAddress.firstName }} {{ resultFrequentAddress.lastName }}<br>
                {{ resultFrequentAddress.unit }} {{ resultFrequentAddress.streetNo }} {{ resultFrequentAddress.streetName }}<br>
                {{ resultFrequentAddress.city }}, {{ resultFrequentAddress.province }} {{ resultFrequentAddress.postal }}<br>
                Canada</p>
              <div class="links d-flex">              
                <a href="#" @click.prevent="navigateToPage('/userAddressDetail')">{{ $t('myAccSettingsManageAddress') }} ></a>
              </div>
            </div>
            <div v-if="!resultFrequentAddress.firstName">
              <p>{{ $t('myAccSettingsNoAddress') }}</p>
              <div class="links d-flex">
                <a href="#" @click.prevent="navigateToPage('/addUserAddress')">{{ $t('myAccLinkAddNewAddress') }} ></a>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <strong>{{ $t('myAccSettingsContactInfo') }}</strong>
            <p>{{ resultData.firstName }} {{ resultData.lastName }}<br> {{ resultData.email }}<br>{{ resultData.phoneNo }}</p>            
            <div class="links d-flex">
              <a href="#" @click.prevent="navigateToPage('/editAccount')" >{{ $t('myAccSettingsEdit') }} ></a>
            </div>
            <div class="links d-flex">
              <a href="#" @click.prevent="navigateToPage('/changePassword')" >{{ $t('myAccLinkChangePassword') }} ></a>
            </div>            
          </div>
        </div>
      </div>
    </div>    

    <!-- Privacy Section -->
    <div class="row mb-4">
      <div class="col-12 col-md-3">
        <h4>{{ $t('myAccSettingsPrivacy') }}</h4>
      </div>
      <div class="col-12 col-md-9">
        <strong>{{ $t('myAccSettingsPrivacySubTitle') }}</strong>
        <p>{{ $t('myAccSettingsPrivacyText') }}</p>        
      </div>
    </div>

  </div>  

</div>

</template>


<script>

import { useRouter } from 'vue-router'; 
import { useStore } from 'vuex';
import apiClient from '@/apiClient.js';
import { onMounted, onUnmounted, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { getCurrentInstance } from 'vue';


export default {
    setup() {
        const router = useRouter();
        const store = useStore();
        const resultData = ref({});
        const resultFrequentAddress = ref({});
        const { proxy } = getCurrentInstance();
        const toast = useToast();
        const errors = ref({});
        const showMenu = ref(false);
        const menuContainer = ref(null);
        const resultOrders = ref({});        

        const curUser = store.getters.user;       
        if(!store.getters.islogin) {
            router.push('/');
        }        

        const getAccountDetail = async () => {
          try
          {
            const response = await apiClient.post('/Account/getAccountDetail', { userId: curUser.userId});
            console.log('userId=', curUser.userId);

            if(response && response.data) {
              resultData.value = response.data;                 
              if(response.data.frequentAddress) {
                resultFrequentAddress.value = response.data.frequentAddress;           
              }
              if(response.data.orders) {
                resultOrders.value = response.data.orders;
              }              
            }                        
          } catch(error) {
            if(error.response && error.response.data && error.response.status == 400) {                    
                    errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
                } else {                
                  if(error.response && error.response.status == 401) {
                    toast.error('Your session has expired, please login again.');           
                    store.dispatch('logout');
                    router.push('/');
                  } else {
                    toast.error(error.code);                    
                  }                                                            
            }
          }
        };

        const navigateToPage = (path) => {
          router.push(path);
        };

        const goOrderList = (catalog) => {         
          router.push({name:'userOrderList', params: { catalog: catalog }});          
        };

        const toggleMenu = () => {          
          showMenu.value = !showMenu.value;          
        };

        const handleClickOutside = (event) => {
            if (!menuContainer.value.contains(event.target)) {
              showMenu.value = false;
          }
        };

        const checkToBook = (catalog) => {

          console.log('Menu click:', catalog);
          console.log('Orders:', resultOrders.value.length);
          if(resultOrders.value.length > 0) {
            setTimeout(() => {             
                router.push('/about');
            }, 200);
          } else {
            setTimeout(() => {
              router.push({name:'selectServiceAddress', params:{ catalog: catalog }});
            }, 200);
          }
        };


        onMounted(() => {
          getAccountDetail();
          document.addEventListener('click', handleClickOutside);  
        });

        onUnmounted(() => {
          document.removeEventListener('click', handleClickOutside);
        });

        return {
          curUser,              
          errors,
          getAccountDetail,
          resultData,                    
          resultFrequentAddress,
          navigateToPage,
          toggleMenu,
          handleClickOutside,
          menuContainer,
          showMenu,
          checkToBook,
          resultOrders,                  
          goOrderList,  
        };
    },    
};

</script>



<style scoped>

.container {
  
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;    
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 30px;
}

.card {
  border-radius: 10px;
}

.card-body {
  background-color: white;
  border-radius: 10px;
}

.card-body-noborder {  
  border-radius: 10px;
}


.card-text {
  font-size: 15px;
}

.row {
  margin-bottom: 20px;
  display: flex;  
  justify-content: center;      
  
}

.rowwhite {
  margin-bottom: 0px;
  padding: 0;
  display: flex;  
  background-color: white;  
  justify-content: center;        
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

h4 {
  font-size: 20px;
  font-weight: bold;
}

h5 {
  font-weight: 700;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 54%;  
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #de071c;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-icon {
  margin-right: 5px;
}


.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.row.has-error input {
  border-color: red;
  background-color: #fce8e6 !important;
}

.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.custom-link {    
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.custom-link:hover {
  text-decoration: underline; 
}

.menu-container {  
  position: relative;  
  z-index: 100;
}

.menu-button {
  background-color: #007BFF; /* Bootstrap primary blue */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;  
  outline: none;
  width: 180px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  border-radius: 5px;
  width: 180px;
  right: 20;
  margin-top: 5px;
  text-align: center;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #eeeeee;
}

.dropdown-menu ul li:last-child {
  border-bottom: none;
}

.dropdown-menu ul li:hover {
  background-color: #f5f5f5;
}
</style>