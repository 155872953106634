import axios from "axios";

axios.defaults.withCredentials = true;
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 10000,
    headers: {'Content-Type': 'application/json'}
});

apiClient.interceptors.request.use(
    config => {
        const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
        console.log('Token=',token);
        if(token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;        
    },
    error => {
        return Promise.reject(error);
    }    
);


export default apiClient;