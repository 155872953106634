import { createStore } from 'vuex';

export default createStore({
    state: {
        islogin: localStorage.getItem('islogin') === 'true',        
        user: JSON.parse(localStorage.getItem('curUser')) || null,
        isAdminLogin: localStorage.getItem('isAdminLogin') === 'true',
        admin: JSON.parse(localStorage.getItem('curAdmin')) || null,
        isStaffLogin: localStorage.getItem('isStaffLogin') === 'true',
        staff: JSON.parse(localStorage.getItem('curStaff')) || null,
    },
    mutations: {
        login(state, user) {
            state.islogin = true;
            state.user = user;
            localStorage.setItem('islogin','true');            
            localStorage.setItem('curUser', JSON.stringify(user));            
        },
        logout(state) {
            state.islogin = false;
            state.user = null;
            localStorage.removeItem('islogin');            
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('authToken');
            localStorage.removeItem('curUser');            
        },
        loginAdmin(state, admin) {
            state.isAdminLogin = true;
            state.admin = admin;
            localStorage.setItem('isAdminLogin','true');
            localStorage.setItem('curAdmin', JSON.stringify(admin));
        },
        logoutAdmin(state) {
            state.isAdminLogin = false;
            state.admin = null;
            localStorage.removeItem('isAdminLogin');
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('authToken');
            localStorage.removeItem('curAdmin');
        },
        loginStaff(state, staff) {
            state.isStaffLogin = true;
            state.staff = staff;
            localStorage.setItem('isStaffLogin', 'true');
            localStorage.setItem('curStaff', JSON.stringify(staff));            
        },
        logoutStaff(state) {
            state.isStaffLogin = false;
            state.staff = null;
            localStorage.removeItem('isStaffLogin');
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('authToken');
            localStorage.removeItem('curStaff');
        }
    },
    actions: {
        login({ commit }, user) {
            commit('login', user);            
        },
        logout({commit}) {
            commit('logout');
        },       
        loginAdmin({ commit }, admin) {
            commit('loginAdmin', admin);
        },
        logoutAdmin({ commit }) {
            commit('logoutAdmin');
        },
        loginStaff({ commit }, staff) {
            commit('loginStaff', staff);
        },
        logoutStaff({ commit }) {
            commit('logoutStaff');
        },
    },
    getters: {
        islogin: state => state.islogin,            
        user: state => state.user,
        isAdminLogin: state => state.isAdminLogin,
        admin: state => state.admin,
        isStaffLogin: state => state.isStaffLogin,
        staff: state => state.staff,
    }
});