<template> 
    <div class="container">
    <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3> 
    <hr class="divider-line0" />
    <h2>{{ $t('selectServiceAddressTitle') }}
    <p style="font-size: 16px; font-weight: 600; color: chocolate;"><span style="font-size: 15px; font-weight: 600; color:#333;">{{ $t('selSvrAdr.curSvrTitle') }}:</span> {{ $t(txtCatalog) }}</p></h2>

    <form @submit.prevent="handleSubmit">
    
    <div class="row">      
      <el-radio-group v-model="selectedAddress">
        <el-collapse v-model="activeNames" class="transparent-collapse" accordion>
            <el-collapse-item  name="1">
              <template #title>
                <span class="custom-title">{{ $t('selectServiceAddressDefaultAddressTitle') }}</span>
              </template>
              <template #icon="{ isActive }">
                <span class="icon-ele">
                  {{ isActive ? 'Expanded' : 'Collapsed' }}
                </span>
              </template>
              <div v-if="!defaultAddress" class="links d-flex">
                <span style="color: #007185">No address has been set yet. Please go to address management to add a new address.</span>&nbsp;&nbsp;
                <a href="#" @click.prevent="navigateToPage('/userAddressDetail')" >{{ $t('myAccSettingsManageAddress') }} ></a>
              </div>
              <div v-else>
                <el-radio :value="defaultAddress.addressId" size="large" border style="height: auto; width: 300px; padding: 16px;" class="radio-text">
                  <strong>{{ defaultAddress.firstName }} {{ defaultAddress.lastName }}</strong><br>
                  {{ defaultAddress.unit ? defaultAddress.unit:'' }} {{ defaultAddress.streetNo ? defaultAddress.streetNo:'' }} 
                  {{ defaultAddress.streetName }}<br>
                  {{ defaultAddress.city }}, {{ getProvinceName(Number(defaultAddress.province)) }} {{ defaultAddress.postal }}<br>
                  {{ defaultAddress.country }}                  
                </el-radio>

                <div v-if="selectedAddress === defaultAddress.addressId" style=" padding-left: 10px; padding-right: 0px; padding-top: 20px; padding-bottom: 20px; width: 290px;" class="d-flex justify-content-center">
                  <button type="submit" :disabled="loading" class="submit-button" ><span v-if="!loading">{{ $t('selSvrAdr.txtSubmit') }}</span>
                    <span v-else class="spinner"></span>
                  </button>
                </div>
              </div>

            </el-collapse-item>             
            <el-collapse-item title="Consistency" name="2">
              <template #title>
                <span class="custom-title">{{ $t('selectServiceAddressOtherAddressTitle') }}</span>
              </template>
              <template #icon="{ isActive }">
                <span class="icon-ele">
                  {{ isActive ? 'Expanded' : 'Collapsed' }}
                </span>
              </template>
            <div class="row">
              <div 
                v-for="address in resultAdresses"
                :key="address.addressId"                 
                class="col-md-4 col-sm-6  mb-4"
                style="width: 300px; margin-left: 0px; margin-right: 20px;"                           
              >            
                <el-radio                               
                  :value="address.addressId"
                  size="large" border 
                  style="width: 300px; height: auto; padding: 16px"       
                  class="radio-text"                         
                >
                  <strong>{{ address.firstName }} {{ address.lastName }}</strong><br>
                    {{ address.unit ? address.unit:'' }} {{ address.streetNo ? address.streetNo:'' }} 
                    {{ address.streetName }}<br>
                    {{ address.city }}, {{ getProvinceName(Number(address.province)) }} {{ address.postal }}<br>
                    {{ address.country }}
                </el-radio>
                
                  <div v-if="selectedAddress === address.addressId" style="width: 290px;  padding-left: 10px; padding-right: 0px; padding-top: 20px; padding-bottom: 10px;" class="d-flex justify-content-center">
                    <button type="submit" :disabled="loading" class="submit-button" ><span v-if="!loading">{{ $t('selSvrAdr.txtSubmit') }}</span>
                      <span v-else class="spinner"></span>
                    </button>
                  </div>                  
                </div>
              </div>    
          </el-collapse-item>
        </el-collapse>  
      </el-radio-group>     
  </div>  

  </form>
    </div>
</template>


<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; 
import { onMounted, ref,computed } from 'vue';
import { useToast } from 'vue-toastification';
import { getCurrentInstance } from 'vue';
import apiClient from '@/apiClient.js';
import { SERVICECATALOG,PROVINCES } from '@/constants';


export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const activeNames = ref(['1']);
        const toast = useToast();
        const { proxy } = getCurrentInstance();
        const errors = ref({});
        const defaultAddress = ref('');
        const selectedAddress = ref('');
        const resultAdresses = ref([]);
        const loading = ref(false);     

        const curUser = store.getters.user;
        if(!store.getters.islogin) {
            router.push('/');
        }             

        const catalog = Number(router.currentRoute.value.params.catalog);        

        const item = SERVICECATALOG.find(item => item.Id === catalog);        
        const txtCatalog = item ? item.key : 'emptyKey';

        const getProvinceName = (provinceId) => { return computed(() => {
          const item = PROVINCES.find(item => item.Id === provinceId);
          return item ? item.name : '';
        });};
            
        const loadAddressData = async () => {
          
          try {
            const response = await apiClient.post('/Account/getAccountAddresses',{
              userId: curUser.userId              
            });

            defaultAddress.value = response.data.addresses[0];
            resultAdresses.value = response.data.addresses.filter(ad=>ad.isDefault === false);                            

          } catch(error) {
            if(error.response && error.response.data && error.response.status == 400) {                    
                  errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
              } else {                
                if(error.response && error.response.status == 401) {
                  toast.error('Your session has expired, please login again.');           
                  store.dispatch('logout');
                  router.push('/');
                } else {
                  toast.error(error.code);                    
                }                                                            
            }
          }
        };
        

        const handleSubmit = () => {
          if(!selectedAddress.value) {
            toast.error('An address must be selected.');
            return;
          }          

          loading.value = true;
          
          setTimeout(() => {
            loading.value = false;
            router.push({name:'selectServiceDetail', params: { catalog: catalog, addressId: selectedAddress.value}});
          }, 200);

        };
       
        const navigateToPage = (path) => {
          router.push(path);
        };


        onMounted(() => {
          loadAddressData();          
        });


        return {
            curUser,                               
            activeNames,    
            loadAddressData,
            defaultAddress,
            selectedAddress,
            resultAdresses,
            loading,
            handleSubmit,
            errors,            
            txtCatalog,            
            svrCatalogs: SERVICECATALOG,
            navigateToPage,
            getProvinceName
        };
    }
};
</script>


<style scoped>

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 90%;  
}

button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
  padding-top: 10px;
  padding-bottom: 0px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;         
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

.custom-title {
  font-weight: bold; /* Makes the title bold */
  font-size: 16px;
}

.transparent-collapse {
  background-color: transparent !important; /* Set the background to transparent */
  width: 100%;
  padding: 20px; /* Padding around the collapse */
  padding-bottom: 30px;
  border: none;
}


.icon-ele {
  margin: 0 8px 0 auto;
  color: #409eff;
}

.radio-group {
  max-width: 300px; 
}

.radio-text {
  white-space: normal; 
  word-wrap: break-word;
  display: inline-block;   
  align-items: flex-start;
}

.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

</style>