<template>
    <div class="row">
   <div class="container">
    <h2>{{ $t('forgottenPasswordTitle') }}</h2>
    <form @submit.prevent="handleSubmit">
      
      <!-- Email or Password -->
      <div class="row" :class="{'has-error': errors.emailOrPhone}">
        <input
          type="text"
          id="emailOrPhone"
          v-model="form.emailOrPhone"
          :placeholder="$t('loginUserNameHolder')"
          @focus="clearError('emailOrPhone')"
          required
        />        
      </div>
      <p v-if="errors.emailOrPhone" class="error-message">
        <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.emailOrPhone }} 
      </p>
     
      <hr class="divider-line" />      

      <!-- Validation code -->
      <div class="row">
        <div class="col-validation">
            <img :src="captchaImageUrl" alt="Captcha" @click="refreshCaptcha" id="validationCodeImg"/>
        </div>
        <div class="col col-validation-text" :class="{'has-error': errors.validationCode}">         
          <input
            type="text"
            id="validationCode"
            v-model="form.validationCode"
            :placeholder="$t('createAccValidation')"
            @focus="clearError('validationCode')"
            required
          />
        </div>        
      </div>

      <p v-if="errors.validationCode" class="error-message">
        <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.validationCode }} 
      </p>   

      <div style="display: flex;  justify-content: center;">        
        <div class="links">
          <a href="#" @click.prevent="refreshCaptcha" style="text-align: center;">{{ $t('createAccValidationRenew') }}</a>            
        </div>
      </div>
    
      <hr class="divider-line" />

      <!-- Submit Button -->
      <div class="row">
        <button type="submit" :disabled="loading" class="submit-button" style="width: 40%"><span v-if="!loading">{{ $t('forgottenPasswordSubmit') }}</span>
            <span v-else class="spinner"></span></button>
      </div>
    </form>      
  </div>
</div>
</template>

<script>
import { ref } from 'vue';
import apiClient from '@/apiClient.js';
import { useRouter } from 'vue-router';  
import { useToast } from 'vue-toastification';
import { getCurrentInstance } from 'vue';


export default {
    name: "PasswordReset1",
    setup() {        
        const router = useRouter();
        const errors = ref({});
        const loading = ref(false);     
        const { proxy } = getCurrentInstance();
        const toast = useToast();
        const captchaImageUrl = ref('');
        const form = ref({       
            emailOrPhone: '',        
            validationCode: '',
        });

        const refreshCaptcha = () => {
            fetchCaptcha();
        };

        const fetchCaptcha = async () => {
            try {
                const response = await apiClient.get('/Account/generateValidation', { responseType: 'blob'});
                const blob = response.data;
                captchaImageUrl.value = URL.createObjectURL(blob); 
            } catch(error) {                
                if(error.response && error.response.data && error.response.status==400) {
                    console.error("Error fetching captcha:", error.response.data);
                    errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);
                }
                else {                    
                    toast.error(error.code);
                }
            }
        };

        const handleSubmit = async () => {
            loading.value = true;

            console.log('Form Submitted', form.value);

            try {

                const response = await apiClient.post('/Account/forgottenAccountPassword',{                    
                    emailOrPhone: form.value.emailOrPhone,              
                    validationCode: form.value.validationCode,
                });
                
                // Record userId
                localStorage.setItem('resetPwdUserId', response.data.userId);

                toast.info(response.data.msg);

                setTimeout(() => {
                    router.push('/passwordReset2');
                }, 2000);

            } catch(error) {
                console.error('Reset account password failed:', error);

                if(error.response && error.response.data && error.response.status==400) {                    
                    errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
                }
                else {                    
                    toast.error(error.code);
                }
            } finally {
                loading.value = false;
            }

        };

        const clearError = (field) => {
            if(errors.value[field]) {
                delete errors.value[field];                           
            }            
        };

        return {          
          form,          
          errors,          
          loading,          
          refreshCaptcha,
          captchaImageUrl,
          clearError,
          fetchCaptcha,
          handleSubmit,
        };
    },
    mounted() {
      this.fetchCaptcha();
    }    
};

</script>

<style scoped>
  .container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

h2 {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;
}

.row {
  margin-bottom: 15px;
  display: flex;  
  justify-content: center;      
}


.name-row {
  justify-content: center; 
}

.col {
  display: flex;
  flex-direction: column;      
}

.col-6 {
  width: calc(50% - 5px);
  max-width: 280px;
}

.col-6:first-child {
  margin-right: 10px;
}

.col-7 {
  width: 100%;  
  justify-content: center;
  display: flex;
}

.col-validation {
  width: 170px;     
}

.col-validation-text {
  width: calc(50% - 5px);
  max-width: 240px;
}

.col-validation-label {  
  width:100%;
  display: flex;
  justify-content: center;
}


label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="date"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 560px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 54%;
  
}

button:hover {
  background-color: #0056b3;
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.error-message {
  color: #de071c;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-icon {
  margin-right: 5px;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.row.has-error input {
  border-color: red;
  background-color: #fce8e6 !important;
}

.col.has-error input {
  border-color: red;
  background-color: #fce8e6 !important;
}

.error-box {
  margin-bottom: 20px; 
  padding: 30px;
  background-color: #fae9a3;
  border: 1px solid #dbc156;
  border-radius: 5px;
  text-align: center;
  color: #333;
  position: relative;
  font-size: 14px;  
  width: auto;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}

.error-box::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent #ffe9c8 transparent;
}

.datepicker-container {    
  margin-bottom: 20px;
  position: relative;
  display: flex;  
  justify-content: center;    
  width: 100%;
}

.datepicker-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.vue3-datepicker__calendar {
  position: absolute;
  top: 50px; 
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.vue3-datepicker__calendar-header {
  background-color: #f0f0f0;
}

.vue3-datepicker__calendar-day:hover {
  background-color: #007bff;
  color: #fff;
}

.links {
  text-align: center;    
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

</style>