<template>
  <div id="app">    
    <router-view />
  </div>
</template>

<script>

</script>

<style scoped>

</style>
