<template>    
    <div class="row">
        <div class="container">
            <div class="row">
                <div class="d-flex justify-content-between">
                    <div>
                        <h3>Hi, {{ curAdmin?.userName }}.</h3> 
                    </div>                    
                </div>

                <hr class="divider-line0" />
    
                <h2>Staff Settings</h2>
            </div>
            
            <div class="row" style="padding-bottom: 30px; padding-left: 20px;">
                <div :class="getNoDataClass()">
                    <div class="row">
                    <div v-if="staffData && staffData.length<=0" style="text-align: center;">No staff data</div>
                    <div v-for="staff in staffData" :key="staff?.id" class="summary">
                        <div class="d-flex justify-content-start" style="background-color: #ccc; align-items: center; padding: 10px; padding-left: 16px; font-size: 14px; color:#333;">
                            <div class="avatars" style="justify-content: center; width:100px;">             
                                <div style="display:inline-block; position: relative;">
                                    <el-tooltip :content="'#'+ staff?.dbStaff?.id">
                                        <el-avatar :src="'/staffs/'+ staff?.dbStaff?.staffNo + '.jpg'" fit="contain" class="avatar" :class="setDisabledImgClass(staff?.dbStaff?.disabled)"/>                                
                                    </el-tooltip><br>
                                    <span style="margin-left: -6px; color:white; font-weight: 600; position:relative;">No# {{ staff?.dbStaff?.staffNo }}</span>
                                </div>                               
                            </div>
                            <div style="font-weight: 600; padding-left: 16px; color: white; align-items: center; width: 240px;">                               
                                <el-icon><User /></el-icon> {{ staff?.dbStaff?.firstName }} {{ staff?.dbStaff?.lastName }}<br>
                                <el-icon><Phone /></el-icon> {{ staff?.dbStaff?.phone }} <br>
                                <el-icon><MessageBox /></el-icon> {{ staff?.dbStaff?.email }}<br>
                                <el-checkbox v-model="staff.dbStaff.disabled" label="Disabled" disabled/>
                            </div>                                                                                                               
                        </div>
                        <div><hr class="divider-line1" /></div>
                        <div class="d-flex justify-content-start" style="align-items: center; padding: 10px; padding-left: 16px; font-size: 14px;" :class="setDisabledTextClass(staff?.dbStaff?.disabled)">
                            <div style="align-items: center;">                                                               
                                <el-rate
                                    v-model="staff.rate"
                                    disabled
                                    show-score
                                    text-color="#ff9900"     
                                    score-template="{value}"                               
                                />
                            </div>
                            <div style="padding-left: 30px; align-items: center;">                                                              
                                "{{ staff?.dbStaff?.selfAssessment ? staff?.dbStaff?.selfAssessment : 'No self-assessment' }}"
                            </div>
                        </div>
                        <div><hr class="divider-line1" /></div>
                        <div class="d-flex justify-content-start" style="align-items: center; padding: 10px; padding-left: 16px; font-size: 14px;" :class="setDisabledTextClass(staff?.dbStaff?.disabled)">
                            <div>
                                {{ staff?.dbStaff?.unit }} {{ staff?.dbStaff?.streetNo }} {{ staff?.dbStaff?.streetName }} 
                                &nbsp; {{ staff?.dbStaff?.city }}, {{ getStaffProvinceName(staff?.dbStaff?.provinceId) }} {{ staff?.dbStaff?.postal }}
                            </div>
                        </div>
                        <div><hr class="divider-line1" /></div>
                        <div class="d-flex justify-content-end" style="align-items: center; padding: 10px; padding-left: 16px; font-size: 14px;">
                            <div class="links">
                                <a href="#" @click.prevent="handleResetPwd(staff?.dbStaff?.id, staff?.dbStaff?.staffNo, staff?.dbStaff?.firstName)">Reset password</a> <span style="color:darkgray">|</span> <a href="#">Recalculate rate</a>
                            </div>
                            <div style="padding-right: 10px; padding-left: 20px;" class="links">
                                <a href="#" @click.prevent="handleEditStaffItem(staff?.dbStaff?.id)">Edit</a> <span style="color:darkgray">|</span> <a href="#" @click.prevent="handleRemoveStaffItem(staff?.dbStaff?.id)">Remove</a>
                            </div>
                        </div>
                    </div>
                    <div  class="d-flex justify-content-end row" style="padding-bottom: 20px; padding-top: 0px; width: 98%; font-size: 14px;">
                        <div class="links">
                            <a href="#" @click.prevent="handleAddStaffItem">Add new staff</a>
                        </div>                    
                    </div>
                </div>                
                <el-dialog v-model="staffEditElem.showDlg" width="600" destroy-on-close>
                    <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                        <span style="color: #333; font-size: 16px;; font-weight: 600;">{{ getDlgStaffMainTitle() }}</span>
                    </div>
                    <hr class="divider-line1"/>
                    <div class="d-flex justify-content-center" v-if="staffEditElem.editId>0" style="padding-top: 20px;">
                        <div>
                            <el-input
                            v-model="staffEditElem.staffNo"
                            style="width: 420px"
                            placeholder="Staff No"                            
                            />
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" style="padding-top: 10px;">
                        <div>
                            <el-input
                            v-model="staffEditElem.firstName"
                            style="width: 200px"
                            placeholder="First Name"                            
                            />
                        </div>       
                        <div style="padding-left: 20px;">
                            <el-input
                            v-model="staffEditElem.lastName"
                            style="width: 200px"
                            placeholder="Last Name"
                            />
                        </div>                   
                    </div>                   
                    <div class="d-flex justify-content-center" style="padding-top: 10px;">
                        <div>
                            <el-input
                            v-model="staffEditElem.selfAssessment"
                            style="width: 420px"
                            :autosize="{ minRows: 3, maxRows: 5 }"
                            type="textarea"
                            placeholder="Self-assessment"
                            />
                        </div>                       
                    </div>
                    <div class="d-flex justify-content-center" style="padding-top: 10px;">
                        <div>
                            <el-input
                            v-model="staffEditElem.phone"
                            style="width: 140px"
                            placeholder="Phone"
                            />
                        </div>
                        <div style="padding-left: 20px;">
                            <el-input
                            v-model="staffEditElem.email"
                            style="width: 260px"
                            placeholder="EMail"
                            />
                        </div>
                    </div>
                    <div style="padding-top: 20px; padding-bottom: 20px;"><hr class="divider-line1" /></div>
                    <div class="d-flex justify-content-center">
                        <div>
                            <el-input
                            v-model="staffEditElem.unit"
                            style="width: 140px"
                            placeholder="Apt/Unit"
                            />
                        </div>
                        <div style="padding-left: 20px;">
                            <el-input
                            v-model="staffEditElem.streetNo"
                            style="width: 260px"
                            placeholder="Street Number"
                            />
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" style="padding-top: 10px;">
                        <div>
                            <el-input
                            v-model="staffEditElem.streetName"
                            style="width: 420px"                                                      
                            placeholder="Street Name"
                            />
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" style="padding-top: 10px;">
                        <div>
                            <el-input
                            v-model="staffEditElem.city"
                            style="width: 200px"
                            placeholder="City/Town"                            
                            />
                        </div>
                        <div style="padding-left: 20px;">
                            <el-input
                            v-model="staffEditElem.postal"
                            style="width: 200px"
                            placeholder="Postal/ZIP code"
                            />
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" style="padding-top: 10px;">
                        <div>
                            <el-select
                                v-model="staffEditElem.provinceId"
                                clearable
                                placeholder="Select Province/Territory"
                                style="width: 420px"
                            >
                                <el-option
                                v-for="item in PROVINCES"
                                :key="item.Id"
                                :label="item.name"
                                :value="item.Id"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" style="padding-top: 10px; align-items: center;">
                        <div>
                            <el-checkbox v-model="staffEditElem.disabled" label="Disabled"/>
                        </div>                        
                    </div>
                    <form @submit.prevent="handleSubmitStaffItem">
                        <div class="d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 30px;">
                            <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">{{ getDlgStaffBtnText() }}</span>
                            <span v-else class="spinner"></span>
                            </button>
                        </div>
                    </form>
                </el-dialog>

                <el-dialog v-model="staffResetPwd.showDlg" width="400" destroy-on-close>
                    <div class="d-flex justify-content-center" style="padding-bottom: 0px;">
                        <span style="color: #333; font-size: 16px; font-weight: 600;">Reset Password</span>                        
                    </div>
                    <div class="d-flex justify-content-center" style="padding-bottom: 20px;">
                        <div>
                            {{ staffResetPwd.staffFirstName }}  No#{{ staffResetPwd.staffNo }} #{{ staffResetPwd.staffId }}
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <el-input
                            v-model="staffResetPwd.newPwd"
                            style="width: 240px"
                            type="password"
                            placeholder="New Password"
                            show-password
                        />
                    </div>
                    <div class="d-flex justify-content-center" style="padding-top: 10px;">
                        <el-input
                            v-model="staffResetPwd.confirmPwd"
                            style="width: 240px"
                            type="password"
                            placeholder="Confirm Password"
                            show-password
                        />
                    </div>
                    <form @submit.prevent="handleSubmitStaffResetPwd">
                        <div class="d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 30px;">
                            <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">Reset Password</span>
                            <span v-else class="spinner"></span>
                            </button>
                        </div>
                    </form>
                </el-dialog>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'; 
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { getCurrentInstance,ref,onMounted } from 'vue';
import apiClient from '@/apiClient';
import { PROVINCES } from '@/constants';

const router = useRouter();
const store = useStore();
const { proxy } = getCurrentInstance();
const toast = useToast();

const curAdmin = store.getters.admin;

const errors = ref({}); 
const staffData = ref([]);
const loading = ref(false);
const staffEditElem = ref({
    editId: 0,
    showDlg: false,

    staffNo: '',
    firstName: '',
    lastName: '',
    selfAssessment: '',
    email: '',
    phone: '',
    unit: '',
    streetNo: '',
    streetName: '',
    city: '',
    postal: '',
    provinceId: 0,
    disabled: false,
});


const staffResetPwd = ref({
    showDlg: false,    
    staffId: 0,
    staffNo: '',
    staffFirstName: '',

    newPwd: '',
    confirmPwd: '',
});



function getDlgStaffBtnText() {
    if(staffEditElem.value.editId > 0) {
        return 'Save Staff';
    } else {
        return 'Add Staff';
    }
}


function getDlgStaffMainTitle() {
    if(staffEditElem.value.editId > 0) {
        return 'Edit Staff';
    } else {
        return 'Add New Staff';
    }
}


function handleResetPwd(id,staffNo,staffName) {
    staffResetPwd.value.showDlg = true;
    staffResetPwd.value.staffId = id;
    staffResetPwd.value.staffNo = staffNo;
    staffResetPwd.value.staffFirstName = staffName;

    staffResetPwd.value.newPwd = '';
    staffResetPwd.value.confirmPwd = '';
}


function handleAddStaffItem() {
    staffEditElem.value.editId = 0;
    staffEditElem.value.showDlg = true;

    staffEditElem.value.staffNo = '';
    staffEditElem.value.firstName = '';
    staffEditElem.value.lastName = '';
    staffEditElem.value.selfAssessment = '';
    staffEditElem.value.email = '';
    staffEditElem.value.phone = '';
    staffEditElem.value.unit = '';
    staffEditElem.value.streetNo = '';
    staffEditElem.value.streetName = '';
    staffEditElem.value.city = '';
    staffEditElem.value.postal = '';
    staffEditElem.value.provinceId = '';
    staffEditElem.value.disabled = false;
}


function handleEditStaffItem(id) {
    staffEditElem.value.editId = id;
    staffEditElem.value.showDlg = true;

    const item = staffData.value.find(it => it.dbStaff.id === id);
    staffEditElem.value.staffNo = item?.dbStaff?.staffNo;
    staffEditElem.value.firstName = item?.dbStaff?.firstName;
    staffEditElem.value.lastName = item?.dbStaff?.lastName;
    staffEditElem.value.selfAssessment = item?.dbStaff?.selfAssessment;
    staffEditElem.value.email = item?.dbStaff?.email;
    staffEditElem.value.phone = item?.dbStaff?.phone;
    staffEditElem.value.unit = item?.dbStaff?.unit;
    staffEditElem.value.streetNo = item?.dbStaff?.streetNo;
    staffEditElem.value.streetName = item?.dbStaff?.streetName;
    staffEditElem.value.city = item?.dbStaff?.city;
    staffEditElem.value.postal = item?.dbStaff?.postal;
    staffEditElem.value.provinceId = item?.dbStaff?.provinceId;
    staffEditElem.value.disabled = item?.dbStaff?.disabled;
}


function setDisabledTextClass(disabled) {
    if(disabled) 
        return 'disabledStaff';
    else 
        return 'normalStaff';
}

function setDisabledImgClass(disabled) {
    if(disabled)
        return 'disabledStaffImg';        
}


function getStaffProvinceName(provinceId) {
    const item = PROVINCES.find(it => it.Id === provinceId);
    return item?.name;
}


function getNoDataClass() {
    if(!staffData.value || staffData.value.length<=0) {
        return 'd-flex justify-content-center';
    } else {
        return 'd-flex justify-content-start';
    }
}


async function loadStaffData() {
    try {
        const response = await apiClient.post('/Staff/getStaffData');

        if(response.data) {
            staffData.value = response.data.staffData;
        }

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    }
}


async function handleSubmitStaffItem() {
    
    // Check inputs
    if(!staffEditElem.value.firstName) {
        toast.warning('First name cannot be empty.');
        return;
    }
    if(!staffEditElem.value.email) {
        toast.warning('EMail cannot be empty.');
        return;
    }
    if(!staffEditElem.value.streetName) {
        toast.warning('Street name cannot be empty.');
        return;
    }
    if(!staffEditElem.value.city) {
        toast.warning('City/town cannot be empty.');
        return;
    }
    if(staffEditElem.value.provinceId <= 0) {
        toast.warning('Provicne Id cannot be empty.');
        return;
    }


    loading.value = true;

    try {
        if(staffEditElem.value.editId > 0) {
            const response = await apiClient.post('/Staff/saveStaffItem', {
                staffId: staffEditElem.value.editId,
                staffNo: staffEditElem.value.staffNo,
                firstName: staffEditElem.value.firstName,
                lastName: staffEditElem.value.lastName,
                disabled: staffEditElem.value.disabled,
                selfAssessment: staffEditElem.value.selfAssessment,
                phone: staffEditElem.value.phone,
                email: staffEditElem.value.email,
                unit: staffEditElem.value.unit,
                streetNo: staffEditElem.value.streetNo,
                streetName: staffEditElem.value.streetName,
                city: staffEditElem.value.city,
                postal: staffEditElem.value.postal,
                provinceId: staffEditElem.value.provinceId,
            });
            
            if(response.data) {
                toast.info(response.data.msg);
            }

        } else {
            const response = await apiClient.post('/Staff/addStaffItem', {
                firstName: staffEditElem.value.firstName,
                lastName: staffEditElem.value.lastName,
                disabled: staffEditElem.value.disabled,
                selfAssessment: staffEditElem.value.selfAssessment,
                phone: staffEditElem.value.phone,
                email: staffEditElem.value.email,
                unit: staffEditElem.value.unit,
                streetNo: staffEditElem.value.streetNo,
                streetName: staffEditElem.value.streetName,
                city: staffEditElem.value.city,
                postal: staffEditElem.value.postal,
                provinceId: staffEditElem.value.provinceId,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }
        }

        loading.value = false;
        staffEditElem.value.showDlg = false;

        await loadStaffData();

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    } finally {
        loading.value = false;
    }
}


async function handleRemoveStaffItem(id) {
    if(confirm(`Are you sure you want to remove the staff, Id=${id}?`)) {
        try {
            const response = await apiClient.post('/Staff/removeStaffItem',{
                staffId: id,
            });

            if(response.data) {
                toast.info(response.data.msg);
            }

            await loadStaffData();

        } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
            if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logoutAdmin');
                router.push('/');
            } else {
                toast.error(error.code);                    
            }                                                            
        }
        }
    }
}


async function handleSubmitStaffResetPwd() {
    // Check inputs
    if(!staffResetPwd.value.newPwd || staffResetPwd.value.newPwd == '') {
        toast.warning('Password cannot be empty.');
        return;
    }   
    if(staffResetPwd.value.newPwd != staffResetPwd.value.confirmPwd) {
        toast.warning('Passwords you entered do not match.');
        return;
    }

    try {
        loading.value = true;

        const response = await apiClient.post('/Staff/resetStaffPassword', {
            staffId: staffResetPwd.value.staffId,
            newPassword: staffResetPwd.value.newPwd,
        });

        if(response.data) {
            toast.info(response.data.msg);
        }

        loading.value = false;
        staffResetPwd.value.showDlg = false;     

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
            if(error.response && error.response.status == 401) {
                toast.error('Your session has expired, please login again.');           
                store.dispatch('logoutAdmin');
                router.push('/');
            } else {
                toast.error(error.code);                    
            }                                                            
        }
    } finally {
        loading.value = false;
    }
}


onMounted(() =>{    

if(!store.getters.isAdminLogin) {
    router.push('/admin');
    return;
} 

loadStaffData();

});

</script>


<style scoped>

.container {
  
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;    
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 30px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;  
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

h4 {
  font-size: 20px;
  font-weight: bold;
}

.links {
  text-align: left;
  width: auto;
}

.links a {  
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 96%;
}

.avatars {
  display: flex;
  align-items: center;
  background: #ccc;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid white;
  margin-left: -10px; 
}

.disabledStaffImg {
  filter: grayscale(100);
}
.disabledStaff {
  color: #CDD0D6;
}
.normalStaff {
  color:#333;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 54%;
  
}

button:hover {
  background-color: #0056b3;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

</style>