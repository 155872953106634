<template>    
    <div class="row">
        <div class="container">
            <div class="row">
                <div class="d-flex justify-content-between">
                    <div>
                        <h3>Hi, {{ curAdmin?.userName }}.</h3> 
                    </div>                    
                </div>

                <hr class="divider-line0" />
    
                <h2>Order Settings</h2>
            </div>

            <div class="row" style="padding-bottom: 10px; padding-left: 20px;">
              <el-tabs v-model="activeTabAll" tab-position="top" class="demo-tabs"  @tab-click="handleAllTabClick">
                <el-tab-pane name="1">             
                  <template #label>
                    <span style="font-size: 18px; font-weight: 600;">Pending Orders</span>
                  </template>                       
                        <div class="row">
                            <el-tabs v-model="activeCatalogPending" tab-position="left" class="demo-tabs"  @tab-click="handlePendingTabClick">
                                <el-tab-pane v-for="i in catalogCount" :key="i" :label="$t(getTxtCatalog(i))" :name="i">
                                    <div :class="getNoDataClass(pagingOrderData)" style="padding-left: 16px;">
                                        <div class="row">
                                            <div class="d-flex justify-content-start" v-if="pagingOrderData.length>0">
                                              <el-pagination
                                                v-model:current-page="pendingCurPageIndex"
                                                v-model:page-size="pendingPageSize"
                                                :page-sizes="[10, 20, 50]"                                                                                                                                 
                                                layout="sizes, prev, pager, next, total"
                                                :total="totalCount"      
                                                @size-change="handlePendingSizeChange"                                         
                                                @current-change="handlePendingCurrentChange"
                                              />
                                            </div>
                                            <div v-if="pagingOrderData && pagingOrderData.length<=0" style="text-align: center;">No order data</div>
                                            <div v-for="order in pagingOrderData" :key="order?.orderId" class="summary">
                                                <div class="d-flex justify-content-between" style="background-color: #F0F2F2; align-items: center; padding: 10px; padding-left: 16px; font-size: 14px; color:#333;">
                                                    <div>
                                                        Placed: {{ MONTHS[new Date(order?.strCreateTime).getMonth()] }}. {{ new Date(order?.strCreateTime).getDate() }},
                                                        {{ new Date(order?.strCreateTime).getFullYear() }}
                                                    </div>
                                                    <div style="color:firebrick">Points: {{ order?.totalPoints }}</div>
                                                    <div style="padding-right: 10px;">
                                                        <el-tooltip :content="'#'+ order?.orderId">
                                                            Order# {{ order?.orderNo }}
                                                        </el-tooltip>
                                                    </div>                                                    
                                                </div>
                                                <div><hr class="divider-line1"/></div>
                                                <div class="d-flex justify-content-start" style="align-items: center; padding-top: 10px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                                    <div style="color: chocolate; font-weight: 600; font-size: 16px; width: 200px;">
                                                        {{ order?.name }}
                                                    </div>
                                                    <div style="font-size: 14px; color: #0aa06e; font-weight: 600;">
                                                        {{ $t(WEEKDAYS[new Date(order?.strBookTime).getDay()]) }}, {{ MONTHS[new Date(order?.strBookTime).getMonth()] }}. 
                                                        {{ new Date(order?.strBookTime).getDate() }}, {{ new Date(order?.strBookTime).getFullYear() }} 
                                                        <span v-if="activeCatalogPending === 1">by {{ new Date(order?.strBookTime).getHours() }}:{{ new Date(order?.strBookTime).getMinutes() === 0 ? '00' : new Date(order?.strBookTime).getMinutes() }}  {{ getTxtSlot(new Date(order?.strBookTime).getHours()) }}</span>                                                        
                                                    </div>
                                                    <div style="padding-left: 10px;"><span style="font-weight: 600; color:#333;">MDT</span></div>
                                                </div>
                                                <div class="d-flex justify-content-start" style="align-items: center; padding: 6px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                                    <div style="margin-top: 3px;">
                                                        <el-icon><AddLocation /></el-icon>
                                                    </div>
                                                    <div style="padding-left: 4px;" v-if="!order?.isConfirmed">
                                                        <el-popconfirm
                                                            width="320"                                                                                                   
                                                            icon-color="#626AEF"
                                                            title="Are you sure to confirm this property?"
                                                            @cancel="onCancel"
                                                        >
                                                        <template #reference>
                                                        <el-tag type="warning">{{ order?.unit }} {{ order?.streetNo }} {{ order?.streetName }} {{ order?.city }}, {{ getTxtProvinceName(order?.provinceId) }} {{ order?.postal }}</el-tag>
                                                        </template>
                                                        </el-popconfirm>
                                                    </div>
                                                    <div style="padding-left: 4px;" v-else>
                                                        {{ order?.unit }} {{ order?.streetNo }} {{ order?.streetName }} {{ order?.city }}, {{ getTxtProvinceName(order?.provinceId) }} {{ order?.postal }}
                                                    </div>                                                    
                                                    <div style="padding-left: 48px; margin-top: 3px;">
                                                        <el-icon><User /></el-icon>
                                                    </div>
                                                    <div style="padding-left: 4px;">
                                                        {{ order?.firstName }} {{ order?.lastName }}
                                                    </div>
                                                    <div style="padding-left: 48px; margin-top: 3px;"><el-icon><Phone /></el-icon></div>
                                                    <div style="padding-left: 4px;">{{ order?.phoneNo }}</div>
                                                    <div style="padding-left: 48px; margin-top: 5px;"><el-icon><MessageBox /></el-icon></div>
                                                    <div style="padding-left: 4px;">{{ order?.emailAddress }}</div>
                                                </div>
                                                <div><hr class="divider-line1"/></div>                                                
                                                <div class="d-flex justify-content-start" style="align-items: center; padding-bottom: 0px; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                                    <div class="row" style="width: 86%;">
                                                      <el-collapse class="transparent-collapse">
                                                        <el-collapse-item  name="1">
                                                          <template #title>
                                                              <span class="custom-title">Summary</span>
                                                          </template>
                                                          <div class="row" style="padding-left: 16px;">
                                                            <div class="summary">
                                                              <div class="d-flex justify-content-between" style="align-items: center; padding-top: 16px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                                                <div style="padding-left: 10px;">Base points:</div>
                                                                <div style="padding-right: 20px; color:firebrick">{{ order?.basePoints }}</div>
                                                              </div>
                                                              <div class="d-flex justify-content-between" style="align-items: center; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                                                <div style="padding-left: 10px;">Additional points:</div>
                                                                <div style="padding-right: 20px; color:firebrick">{{ order?.additionalPoints }}</div>
                                                              </div>
                                                              <div class="d-flex justify-content-between" style="align-items: center; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                                                <div style="padding-left: 10px; color:green;">Rank discount:</div>
                                                                <div style="padding-right: 20px; color:green;">{{ order?.rankDiscount }}%</div>
                                                              </div>
                                                              <div style="width:100%" class="d-flex justify-content-center"><hr class="divider-line2"/></div>
                                                              <div class="d-flex justify-content-between" style="align-items: center;padding-bottom: 30px; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                                                <div style="padding-left: 10px; font-weight: 600; color: firebrick;">Total points:</div>
                                                                <div style="padding-right: 20px; color:firebrick; font-weight: 600;">{{ order?.totalPoints }}</div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </el-collapse-item>
                                                      </el-collapse>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-content-start" style="align-items: center; padding-bottom: 6px; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                                  <div class="row" style="width: 86%;">
                                                    <el-collapse class="transparent-collapse">
                                                      <el-collapse-item  name="1">
                                                        <template #title>
                                                              <span class="custom-title">Change Tracking List</span>
                                                          </template>
                                                          <div class="row" style="padding-left: 16px;">
                                                            <el-table :data="order?.changedList">
                                                              <el-table-column label="Action" width="180">
                                                                <template v-slot="scope">
                                                                    {{ $t(getChangeListColActionName(scope.row.action)) }}
                                                                </template>
                                                              </el-table-column>
                                                              <el-table-column label="Changed time (MDT)" width="360">
                                                                <template v-slot="scope">
                                                                    {{ getChangeListColChangeTime(scope.row.strModifiedTime) }}
                                                                </template>
                                                              </el-table-column>
                                                              <el-table-column label="Operator role">
                                                                <template v-slot="scope">
                                                                    {{ getChangeListColRole(scope.row.srcRole) }}
                                                                </template>
                                                              </el-table-column>
                                                            </el-table>
                                                          </div>
                                                      </el-collapse-item>
                                                    </el-collapse>
                                                  </div>
                                                </div>
                                                <div class="d-flex justify-content-end links" style="align-items: center; padding-bottom: 20px; padding-top: 0px; padding-left: 16px; padding-right: 30px; font-size: 14px;">
                                                    <div style="padding-right: 10px;">                                                      
                                                        <a href="#" @click.prevent="handleAssignStaff(order)">Assign staff to order</a>                                                                                                            
                                                    </div>
                                                    <span style="color:darkgray">|</span> 
                                                    <div style="padding-left: 10px;">
                                                        <a href="#" @click.prevent="handleCancelOrder(order)">Cancel order</a>
                                                    </div>
                                                </div>                                                
                                            </div>                                            
                                        </div>
                                    </div>
                                </el-tab-pane>                                
                            </el-tabs>                            
                        </div>               
                </el-tab-pane>
                <el-tab-pane name="2">
                  <template #label>
                    <span style="font-size: 18px; font-weight: 600;">Other Orders</span>
                  </template>
                  <div class="row">
                    <el-tabs v-model="activeCatalogOther" tab-position="left" class="demo-tabs"  @tab-click="handleOtherTabClick">
                      <el-tab-pane v-for="i in catalogCount" :key="i" :label="$t(getTxtCatalog(i))" :name="i">
                        <div :class="getNoDataClass(pagingOrderData)" style="padding-left: 16px;">
                          <div class="row" style="width: 100%;">
                            <div class="d-flex justify-content-start" v-if="pagingOrderData.length>0">
                              <el-pagination
                                v-model:current-page="otherCurPageIndex"
                                v-model:page-size="otherPageSize"
                                :page-sizes="[10, 20, 50]"                                                                                                                                 
                                layout="sizes, prev, pager, next, total"
                                :total="totalCount"      
                                @size-change="handleOtherSizeChange"                                         
                                @current-change="handleOtherCurrentChange"
                              />
                            </div>
                            <div v-if="pagingOrderData && pagingOrderData.length<=0" style="text-align: center;">No order data</div>
                            <div v-for="order in pagingOrderData" :key="order?.orderId" class="summary">
                              <div class="d-flex justify-content-between" style="background-color: #F0F2F2; align-items: center; padding: 10px; padding-left: 16px; font-size: 14px; color:#333;">
                                <div>
                                    Placed: {{ MONTHS[new Date(order?.strCreateTime).getMonth()] }}. {{ new Date(order?.strCreateTime).getDate() }},
                                    {{ new Date(order?.strCreateTime).getFullYear() }}
                                </div>
                                <div style="color:firebrick">Points: {{ order?.totalPoints }}</div>
                                <div style="padding-right: 10px;">
                                    <el-tooltip :content="'#'+ order?.orderId">
                                        Order# {{ order?.orderNo }}
                                    </el-tooltip>
                                </div>                                                    
                              </div>
                              <div><hr class="divider-line1"/></div>
                              <div class="d-flex justify-content-start" style="align-items: center; padding: 6px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                <div style="color: chocolate; font-weight: 600; font-size: 16px; width: 200px;">
                                    {{ order?.name }}
                                </div>
                                <div style="font-size: 14px; color: #0aa06e; font-weight: 600;">
                                    {{ $t(WEEKDAYS[new Date(order?.strBookTime).getDay()]) }}, {{ MONTHS[new Date(order?.strBookTime).getMonth()] }}. 
                                    {{ new Date(order?.strBookTime).getDate() }}, {{ new Date(order?.strBookTime).getFullYear() }} 
                                    <span v-if="activeCatalogPending === 1">by {{ new Date(order?.strBookTime).getHours() }}:{{ new Date(order?.strBookTime).getMinutes() === 0 ? '00' : new Date(order?.strBookTime).getMinutes() }}  {{ getTxtSlot(new Date(order?.strBookTime).getHours()) }}</span>                                                        
                                </div>
                                <div style="padding-left: 10px;"><span style="font-weight: 600; color:#333;">MDT</span></div>
                                <div :style="{color: getStatusColor(order?.status)}" style="font-weight: 600; padding-left: 80px;">{{ $t(getOrderStatusText(order?.status)) }}</div>
                              </div>
                              <div class="d-flex justify-content-start" style="align-items: center; padding: 6px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                  <div style="margin-top: 3px;">
                                      <el-icon><AddLocation /></el-icon>
                                  </div>
                                  <div style="padding-left: 4px;" v-if="!order?.isConfirmed">
                                      <el-popconfirm
                                          width="320"                                                                                                   
                                          icon-color="#626AEF"
                                          title="Are you sure to confirm this property?"
                                          @cancel="onCancel"
                                      >
                                      <template #reference>
                                      <el-tag type="warning">{{ order?.unit }} {{ order?.streetNo }} {{ order?.streetName }} {{ order?.city }}, {{ getTxtProvinceName(order?.provinceId) }} {{ order?.postal }}</el-tag>
                                      </template>
                                      </el-popconfirm>
                                  </div>
                                  <div style="padding-left: 4px;" v-else>
                                      {{ order?.unit }} {{ order?.streetNo }} {{ order?.streetName }} {{ order?.city }}, {{ getTxtProvinceName(order?.provinceId) }} {{ order?.postal }}
                                  </div>                                                    
                                  <div style="padding-left: 48px; margin-top: 3px;">
                                      <el-icon><User /></el-icon>
                                  </div>
                                  <div style="padding-left: 4px;">
                                      {{ order?.firstName }} {{ order?.lastName }}
                                  </div>
                                  <div style="padding-left: 48px; margin-top: 3px;"><el-icon><Phone /></el-icon></div>
                                  <div style="padding-left: 4px;">{{ order?.phoneNo }}</div>
                                  <div style="padding-left: 48px; margin-top: 5px;"><el-icon><MessageBox /></el-icon></div>
                                  <div style="padding-left: 4px;">{{ order?.emailAddress }}</div>
                              </div>
                              <div><hr class="divider-line1"/></div>
                              <div v-if="order?.staffs?.length>0" class="d-flex justify-content-start" style="align-items: center; padding-bottom: 0px; padding-top: 16px; padding-left: 24px; padding-right: 16px; font-size: 14px;">                                
                                  <div class="d-flex justify-content-start" style="font-weight: 600; align-items: center; padding-bottom: 0px; padding-top: 0px; padding-right: 26px; font-size: 14px;">
                                    Service Staffs
                                  </div>                                  
                                  <div v-for="staff in order?.staffs" :key="staff?.staffId">
                                    <el-popover
                                      :width="300"
                                      popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
                                    >
                                    <template #reference>
                                      <el-avatar :src="'/staffs/'+ staff?.staffNo + '.jpg'" fit="contain" class="avatarList"/>
                                    </template>
                                    <template #default>
                                      <div class="d-flex justify-content-start" style="font-weight: 600;">
                                        No# {{ staff?.staffNo }}
                                      </div>
                                      <div class="d-flex justify-content-start">                                                    
                                        {{ staff?.firstName }} {{ staff?.lastName }}
                                      </div>
                                      <div class="d-flex justify-content-start" style="align-items: center;">
                                        <div>
                                          <el-rate
                                              v-model="staff.rate"
                                              disabled                                                                                                                                                                                                                                       
                                          />
                                        </div>
                                        <div style="color: #ff9900; margin-top: 5px;">
                                          {{ staff?.rate }}
                                        </div>
                                      </div>
                                    </template>
                                    </el-popover>
                                  </div>
                              </div>    
                              <div><hr class="divider-line1"/></div>                          
                              <div class="d-flex justify-content-start" style="align-items: center; padding-bottom: 0px; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                  <div class="row" style="width: 86%;">
                                    <el-collapse class="transparent-collapse">
                                      <el-collapse-item  name="1">
                                        <template #title>
                                            <span class="custom-title">Summary</span>
                                        </template>
                                        <div class="row" style="padding-left: 16px;">
                                          <div class="summary">
                                            <div class="d-flex justify-content-between" style="align-items: center; padding-top: 16px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                              <div style="padding-left: 10px;">Base points:</div>
                                              <div style="padding-right: 20px; color:firebrick">{{ order?.basePoints }}</div>
                                            </div>
                                            <div class="d-flex justify-content-between" style="align-items: center; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                              <div style="padding-left: 10px;">Additional points:</div>
                                              <div style="padding-right: 20px; color:firebrick">{{ order?.additionalPoints }}</div>
                                            </div>
                                            <div class="d-flex justify-content-between" style="align-items: center; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                              <div style="padding-left: 10px; color:green;">Rank discount:</div>
                                              <div style="padding-right: 20px; color:green;">{{ order?.rankDiscount }}%</div>
                                            </div>
                                            <div style="width:100%" class="d-flex justify-content-center"><hr class="divider-line2"/></div>
                                            <div class="d-flex justify-content-between" style="align-items: center;padding-bottom: 30px; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                              <div style="padding-left: 10px; font-weight: 600; color: firebrick;">Total points:</div>
                                              <div style="padding-right: 20px; color:firebrick; font-weight: 600;">{{ order?.totalPoints }}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </el-collapse-item>
                                    </el-collapse>
                                  </div>
                              </div>
                              <div class="d-flex justify-content-start" style="align-items: center; padding-bottom: 6px; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                <div class="row" style="width: 86%;">
                                  <el-collapse class="transparent-collapse">
                                    <el-collapse-item  name="1">
                                      <template #title>
                                            <span class="custom-title">Change Tracking List</span>
                                        </template>
                                        <div class="row" style="padding-left: 16px;">
                                          <el-table :data="order?.changedList">
                                            <el-table-column label="Action" width="180">
                                              <template v-slot="scope">
                                                  {{ $t(getChangeListColActionName(scope.row.action)) }}
                                              </template>
                                            </el-table-column>
                                            <el-table-column label="Changed time (MDT)" width="360">
                                              <template v-slot="scope">
                                                  {{ getChangeListColChangeTime(scope.row.strModifiedTime) }}
                                              </template>
                                            </el-table-column>
                                            <el-table-column label="Operator role">
                                              <template v-slot="scope">
                                                  {{ getChangeListColRole(scope.row.srcRole) }}
                                              </template>
                                            </el-table-column>
                                          </el-table>
                                        </div>
                                    </el-collapse-item>
                                  </el-collapse>
                                </div>
                              </div>
                              <div class="d-flex justify-content-end links" style="align-items: center; padding-bottom: 20px; padding-top: 0px; padding-left: 16px; padding-right: 30px; font-size: 14px;">                                  
                                  <div style="padding-right: 10px;" v-if="order?.status === 1">
                                    <a href="#" @click.prevent="handleResendConfirmation(order)">Resend confirmation notification</a>
                                  </div>
                                  <span style="color:darkgray" v-if="order?.status === 1">|</span> 
                                  <div style="padding-right: 10px; padding-left: 10px;">
                                    <a href="#" @click.prevent="handleAssignStaff(order)">Assign staff to order</a> 
                                  </div>
                                  <span style="color:darkgray">|</span> 
                                  <div style="padding-right: 10px; padding-left: 10px;">                                                      
                                      <a href="#">Reschedule</a>                                                                                                            
                                  </div>
                                  <span style="color:darkgray">|</span> 
                                  <div style="padding-left: 10px;">
                                      <a href="#" @click.prevent="handleCancelOrder(order)">Cancel order</a>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                </el-tab-pane>
                <el-tab-pane name="3">
                  <template #label>
                    <span style="font-size: 18px; font-weight: 600;">History Orders</span>
                  </template>
                  <div class="row">
                    <el-tabs v-model="activeCatalogHistory" tab-position="left" class="demo-tabs"  @tab-click="handleHistoryTabClick">
                      <el-tab-pane v-for="i in catalogCount" :key="i" :label="$t(getTxtCatalog(i))" :name="i">
                        <div :class="getNoDataClass(pagingOrderData)" style="padding-left: 16px;">
                          <div class="row" style="width: 100%;">
                            <div class="d-flex justify-content-start" v-if="pagingOrderData.length>0">
                              <el-pagination
                                v-model:current-page="hisCurPageIndex"
                                v-model:page-size="hisPageSize"
                                :page-sizes="[10, 20, 50]"                                                                                                                                 
                                layout="sizes, prev, pager, next, total"
                                :total="totalCount"      
                                @size-change="handleHistorySizeChange"                                         
                                @current-change="handleHistoryCurrentChange"
                              />
                            </div>
                            <div v-if="pagingOrderData && pagingOrderData.length<=0" style="text-align: center;">No order data</div>
                            <div v-for="order in pagingOrderData" :key="order?.orderId" class="summary">
                              <div class="d-flex justify-content-between" style="background-color: #F0F2F2; align-items: center; padding: 10px; padding-left: 16px; font-size: 14px; color:#333;">
                                <div>
                                    Placed: {{ MONTHS[new Date(order?.strCreateTime).getMonth()] }}. {{ new Date(order?.strCreateTime).getDate() }},
                                    {{ new Date(order?.strCreateTime).getFullYear() }}
                                </div>
                                <div style="color:firebrick">Points: {{ order?.totalPoints }}</div>
                                <div style="padding-right: 10px;">
                                    <el-tooltip :content="'#'+ order?.orderId">
                                        Order# {{ order?.orderNo }}
                                    </el-tooltip>
                                </div>                                                    
                              </div>
                              <div><hr class="divider-line1"/></div>
                              <div class="d-flex justify-content-start" style="align-items: center; padding: 6px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                <div style="color: chocolate; font-weight: 600; font-size: 16px; width: 200px;">
                                    {{ order?.name }}
                                </div>
                                <div style="font-size: 14px; color: #0aa06e; font-weight: 600;">
                                    {{ $t(WEEKDAYS[new Date(order?.strBookTime).getDay()]) }}, {{ MONTHS[new Date(order?.strBookTime).getMonth()] }}. 
                                    {{ new Date(order?.strBookTime).getDate() }}, {{ new Date(order?.strBookTime).getFullYear() }} 
                                    <span v-if="activeCatalogPending === 1">by {{ new Date(order?.strBookTime).getHours() }}:{{ new Date(order?.strBookTime).getMinutes() === 0 ? '00' : new Date(order?.strBookTime).getMinutes() }}  {{ getTxtSlot(new Date(order?.strBookTime).getHours()) }}</span>                                                        
                                </div>
                                <div style="padding-left: 10px;"><span style="font-weight: 600; color:#333;">MDT</span></div>
                                <div :style="{color: getStatusColor(order?.status)}" style="font-weight: 600; padding-left: 80px;">{{ $t(getOrderStatusText(order?.status)) }}</div>
                              </div>
                              <div class="d-flex justify-content-start" style="align-items: center; padding: 6px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                <div style="margin-top: 3px;">
                                    <el-icon><AddLocation /></el-icon>
                                </div>
                                <div style="padding-left: 4px;" v-if="!order?.isConfirmed">
                                    <el-popconfirm
                                        width="320"                                                                                                   
                                        icon-color="#626AEF"
                                        title="Are you sure to confirm this property?"
                                        @cancel="onCancel"
                                    >
                                    <template #reference>
                                    <el-tag type="warning">{{ order?.unit }} {{ order?.streetNo }} {{ order?.streetName }} {{ order?.city }}, {{ getTxtProvinceName(order?.provinceId) }} {{ order?.postal }}</el-tag>
                                    </template>
                                    </el-popconfirm>
                                </div>
                                <div style="padding-left: 4px;" v-else>
                                    {{ order?.unit }} {{ order?.streetNo }} {{ order?.streetName }} {{ order?.city }}, {{ getTxtProvinceName(order?.provinceId) }} {{ order?.postal }}
                                </div>
                                <div style="padding-left: 48px; margin-top: 3px;">
                                    <el-icon><User /></el-icon>
                                </div>
                                <div style="padding-left: 4px;">
                                    {{ order?.firstName }} {{ order?.lastName }}
                                </div>
                                <div style="padding-left: 48px; margin-top: 3px;"><el-icon><Phone /></el-icon></div>
                                <div style="padding-left: 4px;">{{ order?.phoneNo }}</div>
                                <div style="padding-left: 48px; margin-top: 5px;"><el-icon><MessageBox /></el-icon></div>
                                <div style="padding-left: 4px;">{{ order?.emailAddress }}</div>
                              </div>
                              <div><hr class="divider-line1"/></div>
                              <div v-if="order?.staffs?.length>0" class="d-flex justify-content-start" style="align-items: center; padding-bottom: 0px; padding-top: 16px; padding-left: 24px; padding-right: 16px; font-size: 14px;">                                
                                  <div class="d-flex justify-content-start" style="font-weight: 600; align-items: center; padding-bottom: 0px; padding-top: 0px; padding-right: 26px; font-size: 14px;">
                                    Service Staffs
                                  </div>                                  
                                  <div v-for="staff in order?.staffs" :key="staff?.staffId">
                                    <el-popover
                                      :width="300"
                                      popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
                                    >
                                    <template #reference>
                                      <el-avatar :src="'/staffs/'+ staff?.staffNo + '.jpg'" fit="contain" class="avatarList"/>
                                    </template>
                                    <template #default>
                                      <div class="d-flex justify-content-start" style="font-weight: 600;">
                                        No# {{ staff?.staffNo }}
                                      </div>
                                      <div class="d-flex justify-content-start">                                                    
                                        {{ staff?.firstName }} {{ staff?.lastName }}
                                      </div>
                                      <div class="d-flex justify-content-start" style="align-items: center;">
                                        <div>
                                          <el-rate
                                              v-model="staff.rate"
                                              disabled                                                                                                                                                                                                                                       
                                          />
                                        </div>
                                        <div style="color: #ff9900; margin-top: 5px;">
                                          {{ staff?.rate }}
                                        </div>
                                      </div>
                                    </template>
                                    </el-popover>
                                  </div>
                              </div>
                              <div><hr class="divider-line1"/></div>
                              <div class="d-flex justify-content-start" style="align-items: center; padding-bottom: 0px; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                  <div class="row" style="width: 86%;">
                                    <el-collapse class="transparent-collapse">
                                      <el-collapse-item  name="1">
                                        <template #title>
                                            <span class="custom-title">Summary</span>
                                        </template>
                                        <div class="row" style="padding-left: 16px;">
                                          <div class="summary">
                                            <div class="d-flex justify-content-between" style="align-items: center; padding-top: 16px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                              <div style="padding-left: 10px;">Base points:</div>
                                              <div style="padding-right: 20px; color:firebrick">{{ order?.basePoints }}</div>
                                            </div>
                                            <div class="d-flex justify-content-between" style="align-items: center; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                              <div style="padding-left: 10px;">Additional points:</div>
                                              <div style="padding-right: 20px; color:firebrick">{{ order?.additionalPoints }}</div>
                                            </div>
                                            <div class="d-flex justify-content-between" style="align-items: center; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                              <div style="padding-left: 10px; color:green;">Rank discount:</div>
                                              <div style="padding-right: 20px; color:green;">{{ order?.rankDiscount }}%</div>
                                            </div>
                                            <div style="width:100%" class="d-flex justify-content-center"><hr class="divider-line2"/></div>
                                            <div class="d-flex justify-content-between" style="align-items: center;padding-bottom: 30px; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                              <div style="padding-left: 10px; font-weight: 600; color: firebrick;">Total points:</div>
                                              <div style="padding-right: 20px; color:firebrick; font-weight: 600;">{{ order?.totalPoints }}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </el-collapse-item>
                                    </el-collapse>
                                  </div>
                              </div>
                              <div class="d-flex justify-content-start" style="align-items: center; padding-bottom: 6px; padding-top: 0px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                                <div class="row" style="width: 86%;">
                                  <el-collapse class="transparent-collapse">
                                    <el-collapse-item  name="1">
                                      <template #title>
                                            <span class="custom-title">Change Tracking List</span>
                                        </template>
                                        <div class="row" style="padding-left: 16px;">
                                          <el-table :data="order?.changedList">
                                            <el-table-column label="Action" width="180">
                                              <template v-slot="scope">
                                                  {{ $t(getChangeListColActionName(scope.row.action)) }}
                                              </template>
                                            </el-table-column>
                                            <el-table-column label="Changed time (MDT)" width="360">
                                              <template v-slot="scope">
                                                  {{ getChangeListColChangeTime(scope.row.strModifiedTime) }}
                                              </template>
                                            </el-table-column>
                                            <el-table-column label="Operator role">
                                              <template v-slot="scope">
                                                  {{ getChangeListColRole(scope.row.srcRole) }}
                                              </template>
                                            </el-table-column>
                                          </el-table>
                                        </div>
                                    </el-collapse-item>
                                  </el-collapse>
                                </div>
                              </div>
                              <div class="d-flex justify-content-end links" style="align-items: center; padding-bottom: 20px; padding-top: 0px; padding-left: 16px; padding-right: 30px; font-size: 14px;">
                                <div style="padding-right: 10px; padding-left: 10px;">                                                      
                                    <a href="#">Re-do</a>                                                                                                            
                                </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                </el-tab-pane>
                </el-tabs>
            </div>
            <div>
              <el-dialog v-model="assignStaffElem.showDlg" width="700"  :close-on-click-modal="false">
                <div class="d-flex justify-content-center" style="padding-bottom: 10px;">
                    <span style="color: #333; font-size: 16px;; font-weight: 600;">Available Staff Group List</span>
                </div>
                <hr class="divider-line1"/>                                
                <div  class="summary">
                  <div class="d-flex justify-content-between" style="background-color: #F0F2F2; align-items: center; padding: 10px; padding-left: 16px; font-size: 14px; color:#333;">
                    <div>
                        Placed: {{ MONTHS[new Date(assignStaffElem.curOrder?.strCreateTime).getMonth()] }}. {{ new Date(assignStaffElem.curOrder?.strCreateTime).getDate() }},
                        {{ new Date(assignStaffElem.curOrder?.strCreateTime).getFullYear() }}
                    </div>
                    <div style="color:firebrick">Points: {{ assignStaffElem.curOrder?.totalPoints }}</div>
                    <div style="padding-right: 10px;">
                        <el-tooltip :content="'#'+ assignStaffElem.curOrder?.orderId">
                            Order# {{ assignStaffElem.curOrder?.orderNo }}
                        </el-tooltip>
                    </div>                                    
                  </div>
                  <div><hr class="divider-line1"/></div>
                  <div class="d-flex justify-content-start" style="align-items: center; padding-top: 10px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                      <div style="color: chocolate; font-weight: 600; font-size: 16px; width: 200px;">
                          {{ assignStaffElem.curOrder?.name }}
                      </div>
                      <div style="font-size: 14px; color: #0aa06e; font-weight: 600;">
                          {{ $t(WEEKDAYS[new Date(assignStaffElem.curOrder?.strBookTime).getDay()]) }}, {{ MONTHS[new Date(assignStaffElem.curOrder?.strBookTime).getMonth()] }}. 
                          {{ new Date(assignStaffElem.curOrder?.strBookTime).getDate() }}, {{ new Date(assignStaffElem.curOrder?.strBookTime).getFullYear() }} 
                          <span v-if="assignStaffElem.curOrder?.serviceCatalog === 1">by {{ new Date(assignStaffElem.curOrder?.strBookTime).getHours() }}:{{ new Date(assignStaffElem.curOrder?.strBookTime).getMinutes() === 0 ? '00' : new Date(assignStaffElem.curOrder?.strBookTime).getMinutes() }}  {{ getTxtSlot(new Date(assignStaffElem.curOrder?.strBookTime).getHours()) }}</span>                                                        
                      </div>
                      <div style="padding-left: 10px;"><span style="font-weight: 600; color:#333;">MDT</span></div>
                  </div>
                  <div class="d-flex justify-content-start" style="align-items: center; padding: 6px; padding-left: 16px; padding-right: 16px; font-size: 14px;">
                    <div style="margin-top: 3px;">
                        <el-icon><AddLocation /></el-icon>
                    </div>
                    <div style="padding-left: 4px;">
                        {{ assignStaffElem.curOrder?.unit }} {{ assignStaffElem.curOrder?.streetNo }} {{ assignStaffElem.curOrder?.streetName }} {{ assignStaffElem.curOrder?.city }}, {{ getTxtProvinceName(assignStaffElem.curOrder?.provinceId) }} {{ assignStaffElem.curOrder?.postal }}
                    </div>
                  </div>
                </div>                                          
                <div class="d-flex justify-content-start" style="padding-bottom: 10px; padding-top: 10px; width:100%;">
                  <div style="width: 100%;">
                    <div v-if="assignStaffElem.groupData && assignStaffElem.groupData.length<=0" style="text-align: center;">No order data</div>                                    
                    <el-radio-group v-model="assignStaffElem.selectedGroupId" style="width: 96%;">                                                                         
                    <div v-for="group in assignStaffElem.groupData" :key="group?.dbGroup?.id" style="width: 100%; margin: 10px;">
                      <el-radio                                         
                        :value="group.dbGroup.id"
                        size="large" border                       
                        style="height: auto; width: 100%; padding: 16px;"         
                        class="radio-text"
                        >
                        <div class="d-flex justify-content-start" style="align-items: center;">
                          <div><el-tooltip :content="'#'+ group?.dbGroup?.id"><strong>GroupNo# {{ group?.dbGroup?.groupNo }}</strong> </el-tooltip></div>
                          <div style="padding-left: 30px; padding-right: 40px;"><strong>Staffs: {{ group?.staffs.length }}</strong></div>  
                          <div v-for="staff in group.staffs" :key="staff?.staffId" style="margin-right: 6px;">
                              <div class="d-flex justify-content-center">
                              <div>
                                <el-popover
                                  :width="300"
                                  popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
                                >
                                <template #reference>
                                  <el-avatar :src="'/staffs/'+ staff?.staffNo + '.jpg'" fit="contain" class="avatar"/>
                                </template> 
                                <template #default>
                                  <div class="d-flex justify-content-start" style="font-weight: 600;">
                                    No# {{ staff?.staffNo }}
                                  </div>
                                  <div class="d-flex justify-content-start">                                                    
                                    {{ staff?.firstName }} {{ staff?.lastName }}
                                  </div>
                                  <div class="d-flex justify-content-start" style="align-items: center;">
                                    <div>
                                      <el-rate
                                          v-model="staff.rate"
                                          disabled                                                                                                                                                                                                                                       
                                      />
                                    </div>
                                    <div style="color: #ff9900; margin-top: 5px;">
                                      {{ staff?.rate }}
                                    </div>
                                  </div>
                                </template>
                                </el-popover>
                              </div>                                              
                              </div>
                              <div class="d-flex justify-content-center">
                              <div><el-tooltip :content="'#'+ staff?.staffId">{{ staff?.firstName }}</el-tooltip></div>
                              </div>                                            
                          </div>
                        </div>                                        
                      </el-radio>
                    </div>
                    </el-radio-group>
                  </div>
                </div>
                <form @submit.prevent="handleSubmitAssignGroup">
                  <div class="d-flex justify-content-center" style="padding-top: 10px; padding-bottom: 30px;">
                      <button type="submit" :disabled="loading" class="submit-button"><span v-if="!loading">Assign selected group to order</span>
                      <span v-else class="spinner"></span>
                      </button>
                  </div>
              </form>
              </el-dialog>
            </div>
            <el-backtop :right="100" :bottom="100" />
        </div>
    </div>  
</template>


<script setup>
import { useRouter } from 'vue-router'; 
import { useStore } from 'vuex';
import { getCurrentInstance,onMounted,ref,computed } from 'vue';
import { useToast } from 'vue-toastification';
import apiClient from '@/apiClient';
import { SERVICECATALOG,MONTHS,WEEKDAYS,PROVINCES,ORDERCHANGEDACTIONS,ORDERMODIFICATIONROLES,ORDERSTATUS } from '@/constants';
import { ElLoading } from 'element-plus'

const router = useRouter();
const store = useStore();
const { proxy } = getCurrentInstance();
const toast = useToast();

const curAdmin = store.getters.admin;

const errors = ref({}); 
const loading = ref(false);
const activeTabAll = ref('1');
const pendingCurPageIndex = ref(1);
const pendingPageSize = ref(10);
const pagingOrderData = ref([]);
const totalCount = ref(0);
const catalogCount = ref(3);
const activeCatalogPending = ref(1);
const activeCatalogOther = ref(1);
const activeCatalogHistory = ref(1);
const otherCurPageIndex = ref(1);
const otherPageSize = ref(10);
const hisCurPageIndex = ref(1);
const hisPageSize = ref(10);
const slotAMStartHour = ref(9);
const slotAMEndHour = ref(11);
const slotPMStartHour = ref(12);
const slotPMEndHour = ref(14);

const assignStaffElem = ref({
  showDlg: false,  

  curOrder: {},
  groupData: [],
  selectedGroupId: 0,
});


async function handleHistorySizeChange() {
  await loadOrderData(activeTabAll.value ,activeCatalogHistory.value, hisCurPageIndex.value, hisPageSize.value);
}

async function handleHistoryCurrentChange() {
  await loadOrderData(activeTabAll.value ,activeCatalogHistory.value, hisCurPageIndex.value, hisPageSize.value);
}

async function handleOtherCurrentChange() {
  await loadOrderData(activeTabAll.value ,activeCatalogOther.value, otherCurPageIndex.value, otherPageSize.value);
}

async function handleOtherSizeChange() {
  await loadOrderData(activeTabAll.value ,activeCatalogOther.value, otherCurPageIndex.value, otherPageSize.value);
}

async function handlePendingCurrentChange() {
  await loadOrderData(activeTabAll.value, activeCatalogPending.value, pendingCurPageIndex.value, pendingPageSize.value);
}

async function handlePendingSizeChange() {
  await loadOrderData(activeTabAll.value, activeCatalogPending.value, pendingCurPageIndex.value, pendingPageSize.value);
}


function getOrderStatusText(status) {    
    return ORDERSTATUS[status];    
}

function getStatusColor(status) {

if(status>5) {
  return 'chocolate';
} else {
switch(status) {
  case 0:
    return 'chocolate';
  case 1:
    return '#0aa06e';
  case 2:
    return '#00CED1';
  case 3:
    return '#007BFF';
  case 4:
    return '#8B0000';
  case 5:
    return '#DC3545';      
}
}
}

function getNoDataClass(data) {    
    if(data.length<=0) {
        return 'd-flex justify-content-center';
    } else {
        return 'd-flex justify-content-start';
    }
}

function getTxtCatalog(serviceCatalog) {  
  const catalog = computed(() => {
    const catalogItem = SERVICECATALOG.find(item => item.Id === serviceCatalog);  
    return catalogItem ? catalogItem.key : 'emptyKey'; 
  });
  if(catalog.value) {
    return catalog.value;
  } else {
    return 'emptyKey';
  }
}

function getTxtSlot(hour) {
  if(hour >= slotAMStartHour.value && hour <= slotAMEndHour.value)
    return 'AM';
  else if(hour >= slotPMStartHour.value && hour <= slotPMEndHour.value)
    return 'PM';
  else {
    if(hour < slotPMStartHour.value)
      return 'AM';
    else
    return 'PM';
  }    
}


function getTxtProvinceName(provinceId) {
    return computed(() => {
    const item = PROVINCES.find(item => item.Id === provinceId);
    return item ? item.name : '';
  });  
}

async function handleOtherTabClick(tab) {

    activeCatalogOther.value = Number(tab.paneName);

    await ReLoadOrderData();
}

async function handlePendingTabClick(tab) {
        
    activeCatalogPending.value = Number(tab.paneName);
    
    await ReLoadOrderData();
}

async function handleHistoryTabClick(tab) {
  
  activeCatalogHistory.value = Number(tab.paneName);

  await ReLoadOrderData();
}

async function handleAllTabClick(tab) {  

  activeTabAll.value = tab.paneName;

  await ReLoadOrderData();
} 


function getChangeListColActionName(actionId) {
  const item = ORDERCHANGEDACTIONS.find(it => it.Id === actionId);
  return item ? item.key : 'emptyKey';
}

function getChangeListColChangeTime(time) {
  const changedTime = new Date(time);
  const slot = changedTime.getHours() >= 12 ? 'PM' : 'AM';
  return `${MONTHS[changedTime.getMonth()]}. ${changedTime.getDate()}, ${changedTime.getFullYear()} ${changedTime.getHours()}:${changedTime.getMinutes()} ${slot}`;
}

function getChangeListColRole(roleId) {
  const item = ORDERMODIFICATIONROLES.find(it => it.Id === roleId);
  return item?.name;
}
    

async function loadOrderData(type, catalog, pageIndex, pageSize) {

    const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'});
    try {
        const response = await apiClient.post('/Staff/getAdminOrderData', {
            orderByCreateTime: false,
            orderType: type,
            serviceCatalog: catalog,
            curPageIndex: pageIndex,
            pageSize: pageSize,
        });

        if(response.data) {
            pagingOrderData.value = response.data.allData.orderSet;
            totalCount.value = response.data.allData.totalCount;
            
            slotAMStartHour.value = response.data.slotAMStartHour;
            slotAMEndHour.value = response.data.slotAMEndHour;
            slotPMStartHour.value = response.data.slotPMStartHour;
            slotPMEndHour.value = response.data.slotPMEndHour;           
        }

        isLoading.close();

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    }
}


async function handleAssignStaff(curOrder) {
  assignStaffElem.value.showDlg = true;
  assignStaffElem.value.curOrder = curOrder;
  assignStaffElem.value.groupData = [];
  assignStaffElem.value.selectedGroupId = 0;

  try {
    const response = await apiClient.post('/Staff/getAdminOrderAvailableStaffGroup', {
      userId: curOrder.userId,
      orderNo: curOrder.orderNo,
    });

    if(response.data) {
      assignStaffElem.value.groupData = response.data.groupData;
    }

  } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
        if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logoutAdmin');
            router.push('/admin');
        } else {
            toast.error(error.code);                    
        }                                                            
        }
    }
}


async function handleSubmitAssignGroup() {
  // Check inputs
  if(assignStaffElem.value.selectedGroupId<=0) {
    toast.warning('Must select at least one staff group.');
        return;
  }

  loading.value = true;


  try {
    const response = await apiClient.post('/Staff/assignStaffGroupToOrder', {
      orderId: assignStaffElem.value.curOrder.orderId,
      groupId: assignStaffElem.value.selectedGroupId,
    });

    if(response.data) {
      toast.info(response.data.msg);
    }    

    loading.value = false;
    assignStaffElem.value.showDlg = false;

    await ReLoadOrderData();    

  } catch(error) {
      if(error.response && error.response.data && error.response.status == 400) {                    
          errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
      } else {                
          if(error.response && error.response.status == 401) {
              toast.error('Your session has expired, please login again.');           
              store.dispatch('logoutAdmin');
              router.push('/');
          } else {
              toast.error(error.code);                    
          }                                                            
      }
    } finally {
      loading.value = false;
  } 

}


async function handleCancelOrder(order) {
if(confirm('Are you sure you want to cancel this order?')) {
  try {
    const response = await apiClient.post('/Staff/cancelOrder',{
        userId: order?.userId,
        orderNo: order?.orderNo,
      });

      if(response.data) {
        toast.info(response.data.msg);
      }

      await ReLoadOrderData();

  } catch(error) {
      if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
          if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logout');
            router.push('/');
          } else {
            toast.error(error.code);                    
          }                                                            
      }
    }
  }
}


async function ReLoadOrderData() {
    
  const catalog = ref(1);
  const pageIndex = ref(1);
  const pageSize = ref(10); 

  if(activeTabAll.value === '1') {
    catalog.value = activeCatalogPending.value;
    pageIndex.value = pendingCurPageIndex.value;
    pageSize.value  = pendingPageSize.value;
  } else if(activeTabAll.value === '2') {
    catalog.value = activeCatalogOther.value;
    pageIndex.value = otherCurPageIndex.value;
    pageSize.value = otherPageSize.value;
  } else if(activeTabAll.value === '3') {
    catalog.value = activeCatalogHistory.value;
    pageIndex.value = hisCurPageIndex.value;
    pageSize.value = hisPageSize.value;
  }  

  await loadOrderData(Number(activeTabAll.value), catalog.value, pageIndex.value, pageSize.value);
}


async function handleResendConfirmation(order) {
  if(confirm('Are you sure you want to resend confirmation for this order?')) {
    try {
      const response = await apiClient.post('/Staff/resendConfirmOrderMailNotification', {
        userId: order?.userId,
        orderId: order?.orderId,
      });

      if(response.data) {
        toast.info(response.data.msg);
      }

    } catch(error) {
      if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
          if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logout');
            router.push('/');
          } else {
            toast.error(error.code);                    
          }                                                            
      }
    }
  } 
}



onMounted(() =>{    

if(!store.getters.isAdminLogin) {
    router.push('/admin');
    return;
} 

ReLoadOrderData();

});

</script>


<style scoped>

.container {
  
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;    
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 30px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;  
}

.divider-line2 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 6px;
  margin-top: 10px;
  width: 94%;
}


h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

h4 {
  font-size: 16px;
  font-weight: bold;
}

.links {
  text-align: left;
  width: auto;
}

.links a {  
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 94%;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 54%;
  
}

button:hover {
  background-color: #0056b3;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.custom-title {
  font-weight: bold; /* Makes the title bold */
  font-size: 14px;
}

.transparent-collapse {
  background-color: transparent !important; /* Set the background to transparent */
  width: 100%;
  padding-top: 10px; /* Padding around the collapse */
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  border: none;
}

.icon-ele {
  margin: 0 8px 0 auto;
  color: #409eff;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 4px solid white;    
}

.avatarList {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 4px solid white;    
  margin: -8px;
}

</style>