<template>    
<div class="row">
    <div class="container">
    <h2 style="text-align: center; padding: 20px;">Staff Sign In</h2>
    <form @submit.prevent="login">
        <div class="input-group">
            <input
            type="text"
            id="email"
            v-model="email"
            placeholder="Email"
            @focus="clearError('email')"
            required
            />        
        </div>
        <div class="input-group password-group" :class="{'has-error': errors.password}">       
            <input 
            :type="passwordVisible ? 'text' : 'password'" 
            id="password" 
            v-model="password" 
            :placeholder="$t('loginPasswordHolder')" 
            @focus="clearError('password')"
            required
            />       
            <button type="button" class="password-button" @click="togglePasswordVisibility">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="password-icon"
            >
                <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.875 18.825A10.05 10.05 0 0112 19.5c-5.523 0-10-4.477-10-10S6.477-0.5 12-0.5s10 4.477 10 10a10.05 10.05 0 01-.675 3.875M15 10.5A3 3 0 119 10.5a3 3 0 016 0z"
                />
                <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.243 19.243L4.757 4.757"
                />
            </svg>
            </button>
        </div>
        <p v-if="errors.password" class="error-message">
            <img src="/misc/exclam.png" style="width: 14px;" />&nbsp;{{ errors.password }} 
        </p>
        <button 
            type="submit" 
            class="login-button"
            :disabled="loading"
        >
        <span v-if="!loading">{{ $t('loginBtnSignIn') }}</span>
        <span v-else class="spinner"></span>   
        </button>
    </form>    
    </div>
</div>

</template>


<script setup>
import { ref } from 'vue';
import apiClient from '@/apiClient.js';
import { useToast } from 'vue-toastification';
import { getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const errors = ref({});
const passwordVisible = ref(false);       
const loading = ref(false);
const toast = useToast();
const { proxy } = getCurrentInstance();
const store = useStore();
const router = useRouter();
const email = ref('');
const password = ref('');

function clearError(field) {     
    if(errors.value[field]) {        
        errors.value[field] = '';
    }      
}

function togglePasswordVisibility() {
    passwordVisible.value = !passwordVisible.value;
}


async function login() {
    loading.value = true;

    try {
        const response = await apiClient.post('/Staff/loginStaff',{
            userName: email.value,
            password: password.value,
        });

        const token = response.data.token;
        
        localStorage.setItem('authToken', token);

        errors.value = {};        

        store.dispatch('staffLogin', response.data.staff);

        toast.info(response.data.msg);

        setTimeout(() => {
          router.push('/staffMainPage');
        }, 1000);

    } catch(error) {
        if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
          if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logout');
            router.push('/staffLogin');
          } else {
            toast.error(error.code);                    
          }                                                            
        }
    } finally {
        loading.value = false;
    }
}

</script>


<style scoped>

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}


h2 {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 24px;
  color: #333;    
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 360px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.input-group.has-error input {
  border-color: red;
  background-color: #fce8e6;
}


input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
}

.password-group {
  position: relative;
}

.password-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
  
.password-icon {
  width: 20px;
  height: 20px;
  color: #333;
}

.login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  background-color: #007aff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 18px;
  margin-bottom: 20px;
}

.login-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.login-button:hover {
  background-color: #005bb5;
}

.error-message {
  color: #de071c;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}


</style>